.reset-password {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;

  > div {
    width: 50%;
  }

  &__header {
    display: none;
  }

  @media (max-width: 750px) {
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;

      height: 56px;
      padding: 8px 16px;
      margin-bottom: 38px;

      background-color: var(--gray-primary);
      color: var(--white);

      svg {
        width: 160px;
      }
    }

    > div {
      width: 100%;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #636c79 0%, #a7aeba 100%);
    &-wrapper {
      width: 270px;
      height: 356px;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--white);

    @media (max-width: 500px) {
      padding: 0 16px;
    }
  }

  &__form {
    min-width: 320px;

    @media (max-width: 500px) {
      width: 100%;
    }
    .form-input {
      width: 100% !important;
    }

    &-title {
      @include heading_one;
      display: block;
      margin-bottom: 16px;

      span {
        margin-right: 8px;

        cursor: pointer;

        svg path {
          stroke: var(--black-primary);
        }
      }
    }

    .help-message,
    .error-message {
      @include subtitle;

      display: block;
      margin: 8px 0 24px;
      color: var(--gray-primary);
    }

    .error-message {
      margin: 8px 0 0;
      color: var(--red-primary);
    }
    &-btn {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      > * {
        width: 50%;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
