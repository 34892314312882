.confirm-popup {
  width: 432px;
  padding: 64px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--shadow-light);

  animation: confirmPopupShowAimation 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9)
    forwards;

  -webkit-animation: confirmPopupShowAimation 0.2s
    cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  @media (max-width: 325px) {
    width: 288px;
  }

  @media (max-width: 900px) {
    width: 325px;
    padding: 24px 16px;

    button {
      min-width: 120px !important;
    }
  }

  &__content {
    &-text {
      @include heading_three;
      text-align: center;
      position: relative;
    }

    &-supplier {
      padding: 0px 8px;

      .confirm-popup__list {
        display: flex;
        flex-direction: column;
        margin: 8px 0;

        &-title {
          @include subtitle;
          color: var(--gray-thirdy);
        }

        &-link {
        }
      }

      .confirm-popup__list-item {
        display: flex;
        flex-direction: column;
      }

      .confirm-popup__content-desc {
        @include subtitle;
        color: var(--black-primary);
      }
    }

    &-btn {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      margin-top: 24px;

      button {
        min-width: 144px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

@keyframes confirmPopupShowAimation {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: var(--shadow-light);
  }
}
