.order-offer {
    &_detail {
        display: none;
        @media(max-width: 900px) {
            display: inherit;
            @include heading_three;
            color: var(--black-primary);
            padding-bottom: 16px;
        }
    }
    @media(max-width: 900px) {
        .status-wrapper {
            padding-bottom: 16px;
        }
    }
    .order-page__info-title {
        @media(max-width: 900px) {
          background: var(--white);
          border-radius: 8px;
          padding: 16px;
          box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);
        }
    }
    

    &__card {
        background: var(--white);
        box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);
        border-radius: 8px;
        padding: 24px;
        &-your_company {
            @include subtitle;
            text-align: center;
            color: var(--grey-primary);
            max-width: 288px;
            margin-top: 8px;
            @media(max-width: 900px) {
                max-width: 100%;
            }
        }
        @media(max-width: 900px) {
            padding: 16px;
        }
        h3 {
            padding: 0;
            margin: 0;
            @include heading_three;
            color: var(--green-accept);
        }
        &-description {
            padding-top: 8px;
            padding-bottom: 8px;
            @include subtitle;
            color: var(--black-primary);
        }
        &-count {
            @include heading_two;
            color: var(--black-primary);
        }

        &-title_offer {
            @include heading_five;
            color: var(--black-primary);
            padding-top: 24px;
            @media(max-width: 900px) {
                padding-top: 16px;
            }
        }
    }
}