.my-company-user {
  .textarea-form {
    width: 100%;
  }
  &__body form {
    display: flex;
    flex-wrap: wrap;
  }

  &__body form {
    gap: 0 16px;
    max-width: 592px;
  }

  &__body form .form-input {
    margin-top: 16px;
    gap: 0 16px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__body form .textarea-form {
    margin-top: 16px;
    gap: 0 16px;
  }

  &__body form .btn {
    margin-top: 16px;
    gap: 0 16px;
  }

  &__body form .form-input:nth-child(n + 7) {
    width: 100%;
  }
}
