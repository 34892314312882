.main-header {
  display: flex;
  align-items: center;
  padding: 8px 19px;
  height: 56px;
  background: var(--gray-primary);
  color: var(--white);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 300;

  svg {
    path {
      stroke: var(--white);
    }
  }

  &__logo,
  .notification__icon {
    width: 150px;
    height: 40px;
    margin-right: auto;
    svg {
      path {
        stroke: unset;
      }
    }
  
  }

  .notification-page__header-nav {
    position: absolute;
    left: 20px;
    top: 18px;
    svg {
      display: block !important;
    }
  }

  &__notification {
    position: relative;
    cursor: pointer;

    .notification__icon {
      width: 16px;
      height: 16px;

      svg {
        display: block;
      }
    }

    .notification__counter {
      @include subtitle;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -10px;
      top: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--red-primary);
    }
  }

  &__burger {
    cursor: pointer;
    margin-left: 32px;
  }

  &__navbar {
    position: absolute;
    width: 100%;
    height: 0px;
    padding: 16px;

    top: 100%;
    left: 0;
    z-index: 1000;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    background: var(--lgray-secondary);
    color: var(--gray-secondary);

    transition: all 0.3s ease-in-out;

    overflow: hidden;

    svg,
    svg rect,
    svg path {
      stroke: var(--gray-secondary);
    }

    &-logout {
      margin-top: auto;
      border-top: 1px solid var(--lgray-primary);

      .lang-switcher-temp {
        .nav-list__item {
          margin-bottom: 0 !important;
          padding-top: 8px;
        }
      }
      &-btn {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0px 12px 14px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        span:not(.icon) {
          @include heading_four;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          flex-grow: 1;
          flex-shrink: 1;
        }

        span.icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;

          svg {
            transition: all 0.2s ease;
          }

          &:last-child {
            margin-right: 0px;

            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            flex-grow: 1;
            flex-shrink: 1;
          }
        }
      }
    }

    &.mobile {
      display: flex;
      flex-direction: column;
      .nav-list {
        height: auto;
        overflow-y: auto;

        &__item {
          > div {
            background: transparent !important;
          }
        }
        span {
          color: var(--gray-secondary);
        }

        a.active {
          background: transparent !important;
        }
      }
    }
  }

  .main-header__navbar-logout {
  }
}
