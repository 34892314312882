.my-company-page {
    .container_title {
        margin-bottom: 24px;
        @media(max-width: 767px) {
            margin-bottom: 22px;
        }
    }
    .users-superadmin-page {
        @media(max-width: 900px) {
            margin-top: 16px;
        }
    }
}