.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100%;

  color: var(--white);
  overflow: hidden;

  &__header {
    margin-bottom: 23px;
    svg {
      width: 176px;
      height: 41px;
    }
  }

  &__list-wrapper {
    height: 100%;
    overflow: hidden;
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-item {
      cursor: pointer;
      margin-bottom: 16px;

      a,
      > .item__inner {
        position: relative;
        display: flex;
        align-items: center;

        padding: 12px 12px;
        border-radius: 8px;
        color: var(--white);
        text-decoration: none;
      }

      a:hover,
      > .item__inner:hover {
        opacity: 0.8;
      }

      .item__inner.active {
        background: rgb(74, 81, 94);
      }
    }

    &-item:last-child {
      margin: auto 0 0 0;
    }

    &-item.nav-menu::-webkit-scrollbar {
      width: 0;
      background: transparent;
      -webkit-appearance: none;
    }

    &-item.nav-menu {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .item__inner {
    span:not(.icon) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  span.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    svg {
      transition: all 0.2s ease;

      path {
        stroke: var(--white);
      }
    }

    &:last-child {
      margin-right: 0px;

      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  // span {
  //   @include heading_four;
  // }

  .notification-mobile {
    z-index: -1;
    opacity: 0;
    position: absolute;
    left: 21px;
    top: 4px;

    width: 20px;
    height: 20px;

    transition: opacity 0.2s ease;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;

      background: var(--red-primary);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .notifications-count {
    position: relative;
    flex-shrink: 1;
    width: 24px;
    height: 24px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      background: var(--red-primary);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
