.project-detail-page {
  .company-page {
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 36px;

      &-type {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;

        span {
          @include subtitle;
          color: var(--gray-thirdy);

          margin-left: 26px;

          &:first-letter {
            text-transform: uppercase;
          }
        }

        > div {
          cursor: pointer;
        }

        path {
          stroke: var(--gray-primary);
        }
      }

      &-title {
        margin-left: 44px;
      }
      &-title.mobile {
        display: flex;
        > div {
          margin-right: 8px;

          path {
            stroke: var(--black-primary);
          }
        }
      }
    }

    @media (max-width: 900px) {
      .container_title {
        > div > h1.title > div {
          svg path {
            stroke: var(--black-primary) !important;
          }
        }
      }
    }
    &__content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      > div:nth-child(1) {
        margin-right: 16px;
        width: 66%;
      }

      > div:nth-child(2) {
        width: 33%;
      }

      @media (max-width: 900px) {
        flex-direction: column;

        > div {
          width: 100% !important;
          margin-right: 0px !important;
          padding-top: 8px;
        }

        .company-page__orders-hide {
          display: flex;
          align-items: center;

          span {
            @include heading_four;
            display: flex;
            color: var(--black-primary);
            margin-right: 8px;
          }

          svg {
            path {
              stroke: var(--black-primary);
            }
          }
        }

        .order-card {
          margin-bottom: 16px;
        }
      }
    }

    &__orders {
      display: flex;
      flex-direction: column;
      position: relative;

      > div {
        margin-bottom: 16px;
      }
    }
  }

  .company-page__pagination {
    padding-top: 8px !important;
  }
  @media (max-width: 900px) {
    .company-page__pagination {
      margin-top: 24px !important;
      padding-top: 0px !important;
    }
  }

  .about-card {
    min-width: 100%;

    @media (max-width: 900px) {
      padding: 0;
      margin-bottom: 33px;
      background: transparent;
      box-shadow: none;

      .about-card__title {
        display: none;
      }
    }
  }
}
