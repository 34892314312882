.supplier-company-info {
  &__body {
    margin-top: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 48px;
    width: 398px;

    @media (max-width: 900px) {
      width: 100%;
      padding-left: 0;
    }
    &-item {
      margin-bottom: 8px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      &:last-child {
        flex-direction: column;
        span {
          display: block;
          margin-bottom: 4px;
        }
      }

      span {
        @include subtitle;
      }
      span:first-child {
        color: var(--gray-primary);
      }
      span:last-child {
        color: var(--black-primary);
      }
    }
  }
}
