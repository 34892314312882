.accordion {
    padding-bottom: 16px;
    &-title {
        display: flex;
        justify-content: space-between;
        svg {
            cursor: pointer;
            path {
                stroke: var(--black-primary);
            }
        }
        div {
            @include heading_six();
            font-size: 14px;
        }
    }
    .row {
        > div:last-child {
            max-width: 65%;
        }
        > div:last-child:last-child {
            max-width: unset;
        }
        &:last-child {
            flex-wrap: wrap;
        }
        &-title {
            @include subtitle();
            @media(max-width: 767px) {
                font-weight: 400;
                line-height: 18px;
            }
        }
        &-description {
            @include subtitle();
            @media(max-width: 767px) {
                line-height: 18px;
            }
        }
    }
    &-content {
        padding-top: 12px;
    }
}
