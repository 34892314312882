.company-item {
  position: relative;

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;

    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-light);

    @media (max-width: 900px) {
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__name {
    width: 41.66%;
    cursor: pointer;

    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 16px;
    }

    &-company {
      @include heading_three;

      display: block;
      margin-bottom: 2px;

      color: var(--black-primary);
    }

    &-role {
      @include subtitle;
      color: var(--gray-thirdy);
      text-transform: capitalize;
    }
  }

  &__info {
    @include subtitle;
    display: flex;
    width: 58.33%;

    @media (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }

    &-block {
      display: flex;
      flex-direction: column;
      width: 33.33%;
      padding-right: 16px;

      @media (max-width: 900px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0px;
        margin-bottom: 8px;
      }
    }

    &-title {
      color: var(--gray-thirdy);
    }
    &-desc {
      color: var(--black-primary);
    }
  }

  &__actions {
    position: absolute;
    right: 24px;
    top: 24px;

    cursor: pointer;

    .actions-btn {
      width: 30px;
      height: 28px;
      position: relative;
      right: -11px;

      svg {
        display: block !important;
        margin: 0 auto;
      }
    }

    @media (max-width: 900px) {
      right: 16px;
      top: 16px;
    }
  }
}
