.form-input {
  width: 288px;
  &.success {
    input {
      // border: 1px solid var(--violet-primary);
      border: 1px solid var(--gray-thirdy);
      color: var(--black);
    }
  }

  &__label-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    label {
      @include subtitle;
      display: block;
      color: var(--black-primary);

      &:first-letter {
        text-transform: uppercase;
      }
    }

    label.error {
      color: var(--gray-primary);
    }
  }

  &__input-wrap {
    position: relative;
    margin-bottom: 2px;

    &.icons {
      input {
        padding-right: 30px;
      }
    }

    &.errors {
      .click-icon {
        fill: var(--red-secondary);
      }
    }

    input {
      @include paragraph;
      width: 100%;
      padding: 12px 8px;
      border-radius: 8px;
      border: 1px solid var(--gray-thirdy);
      outline: none;
      height: 44px;

      &::placeholder {
        @include paragraph;
        color: var(--gray-thirdy);
      }
    }

    input:focus,
    input:active {
      border: 1px solid var(--violet-primary);
      outline: none;
    }

    // input:focus-visible {
    //   border: 1px solid var(--violet-primary);
    //   // outline: 4px solid var(--violet-thirdy);
    //   outline: none;
    // }

    input:not(.error):focus {
      // box-shadow: 0px 0px 10px rgba(139, 148, 179, 0.7);
    }

    input.error {
      border: 1px solid var(--red-secondary);
      padding: 12px 26px 12px 8px;
      outline: none;
    }

    .click-icon {
      display: flex;
      position: absolute;
      right: 8px;
      width: 16px;
      height: 16px;
      top: calc(50% - 9px);
      cursor: pointer;

      svg path {
        stroke: var(--red-secondary);
      }
    }

    .danger-icon {
      display: flex;
      position: absolute;
      right: 8px;
      width: 16px;
      height: 16px;
      top: calc(50% - 9px);

      svg path {
        stroke: var(--red-secondary);
      }
    }
  }

  p.error {
    @include subtitle;
    margin: 2px 0 0;
    width: 100%;
    text-align: left;
    color: var(--red-secondary);
  }

  &.disabled {
    label {
      color: var(--gray-primary);
    }

    input {
      background: #f1f0f3;
      border: 1px solid var(--gray-thirdy);
      box-shadow: none !important;
      color: var(--gray-thirdy) !important;

      &::placeholder {
        color: var(--gray-thirdy);
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-outline {
  position: relative;
  &.focus::before {
    position: absolute;
    content: '';
    border: 4px solid var(--violet-thirdy);
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 12px;
  }
}
