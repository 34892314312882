.notification-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: var(--gray-primary);

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 19px;
    height: 56px;
    background: var(--gray-primary);
    color: var(--white);
  }

  &__inner {
    display: flex;
    min-height: 150px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    background: transparent;
  }
  &__content {
    height: 100%;
    width: 100%;
    min-height: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    position: relative;
    background: var(--white);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .notification-list {
    overflow: hidden;
    padding-top: 24px;

    .notification-list__inner {
      height: 100%;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 1px;

      padding: 16px;

      &:first-child {
        padding: 0px 16px 16px 16px;
      }

      border-bottom: 1px solid var(--lgray-primary);

      .link:hover {
        text-decoration: none !important;
      }

      span {
        @include subtitle;

        &:not(.link) {
          color: var(--black-primary);
        }
      }
    }

    &__text {
      position: relative;
      display: flex;
      flex-wrap: nowrap;

      width: 100%;
      justify-content: space-between;

      > span:nth-child(1) {
        margin-right: 8px;
      }

      svg {
        display: block;
        position: relative;
        top: -2px;
        cursor: pointer;
        path {
          stroke: var(--gray-primary);
        }
      }

      &.not-read span {
        @include heading_six;
        line-height: 18px;
      }
    }

    &__date {
      display: block;
      margin-top: 4px;
      color: var(--gray-primary) !important;
    }
  }

  .empty-list {
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 100px;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--violet-thirdy);

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &__title {
      @include paragraph;
      color: var(--gray-primary);
    }
  }

  .notification-delete {
    height: 52px;
    width: 100%;
    margin-top: 8px;
    padding: 16px;
    box-shadow: var(--shadow-light-top);

    &__inner {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }

    svg {
      display: block !important;
      path {
        stroke: var(--violet-primary);
      }
    }
    span {
      @include paragraph;
      color: var(--gray-primary);
      display: block;
      margin-left: 10px;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    overflow: hidden;

    position: absolute;
    right: -30px;
    top: 0;

    cursor: pointer;

    box-shadow: var(--shadow-hard);
    background: var(--white);
    border-radius: 50%;

    > svg path {
      stroke: var(--gray-secondary);
    }
  }

  .container_spinner {
    height: 200px !important;
  }
}
