.orders-supplier-page {
    .company-page {
      &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 24px;
  
        &-type {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 8px;
  
          span {
            @include subtitle;
            color: var(--gray-thirdy);
  
            margin-left: 26px;
  
            &:first-letter {
              text-transform: uppercase;
            }
          }
  
          > div {
            cursor: pointer;
          }
  
          path {
            stroke: var(--gray-primary);
          }
        }
  
        &-title {
          margin-left: 44px;
        }
        &-title.mobile {
          display: flex;
          > div {
            margin-right: 8px;
  
            path {
              stroke: var(--black-primary);
            }
          }
        }
      }
    }
  }
  .order-supplier-list {
    // button.btn.tertiary.medium.detail_role {
    //   background: var(--lgray-primary)!important;
    // }
    .order-card-supplier {
      margin-top: 16px;
    }
    margin-top: 8px;
    @media(max-width: 900px) {
      margin-top: 0px;
    }
    .order-supplier-list__pagination {
      margin-top: 24px;
    }
  }
  