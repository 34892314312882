.datepicker {
  width: 288px;

  &__label-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    label {
      @include subtitle;
      display: block;
      color: var(--black-primary);

      &:first-letter {
        text-transform: uppercase;
      }
    }

    label.error {
      color: var(--gray-primary);
    }
  }

  &__input-wrap {
    position: relative;
    margin-bottom: 2px;

    // &.icons {
    //   input {
    //     padding-right: 30px;
    //   }
    // }

    input {
      @include paragraph;
      width: 100%;
      padding: 12px 8px 12px 35px;
      border-radius: 8px;
      border: 1px solid var(--gray-thirdy);
      outline: none;
      height: 44px;

      &::placeholder {
        @include paragraph;
        color: var(--gray-thirdy);
      }
    }

    input:focus,
    input:active {
      outline: none;
      box-shadow: var(--shadow-light);
      border: 1px solid var(--white);
      border-radius: 8px 8px 0 0;
    }

    input:not(.error):focus {
      // box-shadow: 0px 0px 10px rgba(139, 148, 179, 0.7);
    }

    .danger-icon {
      display: flex;
      position: absolute;
      right: 8px;
      width: 16px;
      height: 16px;
      top: calc(50% - 9px);

      svg path {
        stroke: var(--red-secondary);
      }
    }
  }

  .calendar-icon {
    position: absolute;
    left: 11px;
    top: 14px;
    z-index: 1;

    svg {
      display: block !important;

      path {
        stroke: var(--gray-thirdy);
      }
    }
  }

  .errors {
    input {
      border: 1px solid var(--red-secondary);
      padding: 12px 8px 12px 35px;
      outline: none;
    }
  }

  p.error {
    @include subtitle;
    margin: 2px 0 0;
    width: 100%;
    text-align: left;
    color: var(--red-secondary);
  }

  &.disabled {
    label {
      color: var(--gray-primary);
    }

    input {
      background: #f1f0f3;
      border: 1px solid var(--gray-thirdy);
      box-shadow: none !important;
      &::placeholder {
        color: var(--gray-thirdy);
      }
    }
  }

  .focused {
    input {
      outline: none;
      box-shadow: var(--shadow-light);
      border: 1px solid var(--white);
      border-radius: 8px 8px 0 0;
    }

    .calendar-icon {
      svg {
        path {
          stroke: var(--violet-primary);
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .react-datepicker {
    border-color: var(--white);
    border-radius: 0 0 8px 8px;
  }
  .react-datepicker__header {
    border-bottom: 1px solid transparent;
    background: var(--white);
    border-radius: 0;
  }
  .react-datepicker__current-month {
    @include heading_five;
    color: var(--violet-primary);
    margin-bottom: 8px;
  }
  .react-datepicker__tab-loop {
    width: 100%;
  }
  .react-datepicker-popper {
    width: 100%;
    border-top: 1px solid var(--lgray-primary);
  }

  .react-datepicker__navigation {
    &-icon:before {
      border-color: var(--violet-primary);
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: '';
      display: block;
      height: 6px;
      position: absolute;
      top: 10px;
      width: 6px;
    }
  }
  .react-datepicker__day-names {
    .react-datepicker__day-name {
      color: var(--gray-thirdy);
      @include subtitle;
    }
  }
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker-popper[data-placement^='bottom'],
  .react-datepicker-popper[data-placement^='top'],
  .react-datepicker-popper[data-placement^='left'],
  .react-datepicker-popper[data-placement^='right'] {
    padding: 0px;
  }
  .datapicker-component {
    width: 100%;
    box-shadow: var(--shadow-light);
    .react-datepicker__triangle {
      display: none;
    }
  }

  .react-datepicker__day {
    color: var(--black-primary);
    @include fontRegular;
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: var(--gray-thirdy);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    color: var(--white);
    background: var(--violet-primary);
    outline: none;
  }

  .react-datepicker__day--keyboard-selected:focus-visible {
    outline: none;
  }
}
