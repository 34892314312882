.link {
  @include heading_five();
  color: var(--violet-primary);
  cursor: pointer;

  @media (max-width: 500px) {
    @include subtitle;
  }

  &:hover {
    text-decoration: underline !important;
  }

  &:active {
    color: var(--gray-thirdy);
  }
}
