.add-order {
  .full-width {
    > div {
      width: 100%;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 78px;
    > span {
      @include heading_one;
      display: block;
      text-align: center;
      margin: 24px 0;
    }

    button {
      min-width: 144px;
    }
  }
}
