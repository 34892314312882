.container_title {
  display: flex;

  @media (max-width: 900px) {
    width: 100%;

    > div {
      width: 100%;
    }
    .title {
      position: relative;
      width: 100%;
      .actions {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  // margin-bottom: 24px;
  &-text {
    padding-left: 28px;
  }
  .back-icon svg {
    cursor: pointer;
  }
  path {
    stroke: var(--gray-primary);
  }
}

h2.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px;
  margin-bottom: 8px;
  color: var(--black-primary);
  font-family: 'Inter Semi Bold';
  display: flex;
  @media (max-width: 767px) {
    div {
      padding-right: 12px;
      top: -2px;
      position: relative;
    }
  }
}

h1.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px;
  margin-bottom: 8px;
  color: var(--black-primary);
  font-family: 'Inter Semi Bold';
  display: flex;
  @media (max-width: 767px) {
    div {
      padding-right: 12px;
      top: -2px;
      position: relative;
    }
  }
}

p.title {
  font-family: 'Inter Regular';
  font-style: normal;
  letter-spacing: -0.02em;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray-thirdy);
  margin: 0;
  margin-bottom: 8px;
  margin-top: 3px;
  @media (max-width: 767px) {
    margin-bottom: 2px;
  }
}

h2.error {
  color: var(--red-secondary);
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 8px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.title_container {
  .notification-alert {
    margin-bottom: 31px;
    @media (max-width: 767px) {
      margin-bottom: 21px;
    }
  }
}
