.page-layout {
  display: flex;

  &:before {
    content: '';
    background: var(--gray-primary);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    &:before {
      content: '';
      background: var(--gray-primary);
      width: 100%;
      height: 100px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }

  &__sidebar {
    width: 236px;
    min-width: 236px;
    // min-height: 100vh;
    height: 100vh;
    background: var(--gray-primary);

    padding: 32px 16px;
    transition: all 0.1s ease;

    position: relative;

    &-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      right: -12px;
      top: 32px;

      width: 24px;
      height: 24px;

      z-index: 10;

      background: var(--white);
      border-radius: 50%;
      box-shadow: var(--shadow-hard);
      cursor: pointer;
      transition: all 0.1s ease;

      svg {
        right: 1px;
        position: relative;
      }
    }

    &-btn.collapsed {
      transform: rotate(180deg);
    }

    &.collapsed {
      width: 76px;
      min-width: 76px;

      .notification-mobile {
        z-index: 10;
        opacity: 1;
      }

      .notifications-count {
        opacity: 0;
      }

      .notification-popup__inner {
        left: 68px;
      }
    }
  }

  .fix-mobile-scroll {
    display: block;
    height: 56px;
  }

  &__content {
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 32px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;

    @media (max-width: 900px) {
      padding: 23px 16px 62px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0px;
      // min-height: calc(100vh - 56px);
    }

    background: var(--lgray-secondary);
    overflow-y: auto;
    transition: all 0.6s ease;
  }
}
