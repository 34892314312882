.add-project {
  .full-width {
    > div {
      width: 100%;
    }
  }

  .company-page__header {
    @media (max-width: 900px) {
      margin-bottom: 7px;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 78px;
    > span {
      @include heading_one;
      display: block;
      text-align: center;
      margin: 24px 0;
    }

    &-btn {
      display: flex;
    }

    button:nth-child(1) {
      margin-right: 16px;
    }

    button {
      width: 144px;
      padding: 12px 9px !important;

      @media (max-width: 900px) {
        width: 135px;
        max-width: 200px;
      }
    }
  }
}
