.order-page-customer {
  display: flex;
  .status-wrapper {
    margin-top: 8px;
  }
  .container_title-text {
    padding-left: 20px;
  }

  @media (min-width: 900px) {
    .custom-tab {
      margin-top: 16px;
      &__item {
        min-width: 160px;
      }
    }
  }

  @media (min-width: 900px) {
    .custom-tab__item {
      min-width: 160px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    .custom-tab__item,
    .custom-tab__item-active {
      width: 168px !important;
      min-width: 168px !important;
      display: flex;
    }
  }
  &__tenders {
    padding-top: 28px;
    padding-bottom: 30px;
  }
  &__tenders_sup {
    padding-top: 28px;
    @include heading_three();
    color: var(--black-primary);
    @media (min-width: 900px) {
      display: none;
    }
  }
  &__content {
    &-header {
      display: flex;

      .title {
        span {
          @include heading_one;
        }
      }
      &-count {
        margin-left: 100px;
        margin-top: 29px;
        @media(min-width: 991px) {
          margin-top: 36px;
        }
        span {
          @include heading_three;
        }
      }
      &-msg,
      &-msg-2 {
        display: flex;
        margin-top: 18px;
        @media (min-width: 900px) {
          max-width: 290px;
        }

        .link {
          text-decoration: none !important;
          color: var(--violet-primary);
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          cursor: auto;
        }
        .msg-icon {
          width: 20px;
          height: 20px;
        }
        svg {
          display: block !important;
          margin-top: -2px;
        }

        > span {
          @include subtitle;
          margin-left: 10px;
          display: block;
          color: var(--gray-primary);
        }
      }
      &-msg-2 {
        margin-bottom: 26px;

        @media (max-width: 900px) {
          margin-bottom: 8px;
        }
      }
    }
    &-viewid {
      @include heading_six;
      color: var(--gray-secondary);
      margin-bottom: 4px;
    }
    &-project {
      @include heading_four();
    }
    @media (min-width: 900px) {
      padding-right: 24px;
    }
    @media (min-width: 1100px) {
      width: calc(100% - 316px);
    }
    @media (min-width: 900px) and (max-width: 1100px) {
      width: calc(100% - 256px);
    }
  }
  &__info {
    @media (max-width: 900px) {
      margin-top: 16px;
    }
    z-index: 1;
    &.mobile {
      .infoContent {
        padding-left: 32px;
      }

      .card {
        padding: 12px 16px 16px 16px;
        background-color: var(--white);
        box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);
        border-radius: 8px;
      }

      .text-title {
        margin-bottom: 16px;
        margin-top: 16px;
        @include heading_three();
        color: var(--black-primary);
      }

      .accordion {
        display: unset !important;
      }
      width: 100%;
      margin-top: -8px;
      .container_title {
        margin-bottom: 12px;
      }
    }
    .accordion {
      display: unset;
      > &:last-child .accordion-item {
        border-bottom: unset;
        margin-bottom: unset;
        padding-bottom: unset;
      }

      > &:last-child .accordion-item:last-child {
        margin-bottom: 16px;
      }
    }
    .accordion-item {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--lgray-primary);
      margin-bottom: 16px;
    }

    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);

    @media (max-width: 900px) {
      padding: 16px;
      .accordion {
        display: none;
      }
    }
    &.mobile {
      padding: 0;
      background-color: var(--lgray-secondary);
      box-shadow: unset;
    }
    padding: 24px;
    padding-bottom: 8px;
    @media (min-width: 901px) {
      width: 316px;
      height: fit-content;
      min-height: 220px;
    }
    @media (max-width: 900px) {
      min-height: 200px;
    }
    &-detail {
      color: var(--violet-primary);
      text-align: center;
      margin: -16px -16px;
      margin-top: 0;
      border-radius: 0 0 8px 8px;
      padding: 16px;
      border: 1px solid var(--violet-primary);
      background-color: var(--lgray-secondary);
      cursor: pointer;
    }
    &-title {
      margin-bottom: 24px;
      .row {
        &-description {
          @include subtitle();
          @media (max-width: 900px) {
            line-height: 18px;
          }
        }
      }
      .title {
        @include heading_six();
        position: relative;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: var(--black-primary);
        margin-bottom: 20px;
        @media (max-width: 900px) {
          display: none;
        }
      }
    }
    &-subtitle {
      .title {
        @include heading_five();
        font-size: 14px;
        color: var(--black-primary);
      }
    }
  }

  .row {
    justify-content: space-between;
    display: flex;
    padding-top: 4px;

    div {
      display: flex;
      align-items: center;
      div {
        margin-right: 8px;
      }
    }

    &-title {
      @include heading_five();
      color: var(--gray-primary);
      font-size: 12px !important;
    }

    &-description-link {
      @include subtitle();
      color: var(--violet-primary);
      font-size: 12px !important;
      line-height: 20px;
      font-weight: 600;
    }

    .bold {
      @include heading_five();
      color: var(--black-primary);
      font-size: 12px;
    }

    svg path {
      stroke: #4446b0;
    }

    &.mb-16 {
      margin-bottom: 16px;
    }
    &.mb-8 {
      margin-bottom: 8px;
    }
  }
  .icon {
    &_cyrcle {
      width: 36px;
      height: 36px;
      background-color: var(--violet-thirdy);
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--violet-thirdy);

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &__title {
      @include paragraph;
      color: var(--gray-primary);
    }
  }
  .relative {
    position: relative;
    .container_spinner {
      transform: translate(0px, -25%);
    }
  }

  .offer-tab-list {
    position: relative;
  }

  .offer-tab-list__accept {
    button {
      width: 292px;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

.accepted-offers-inprogress-desctop {
  padding-top: 24px;

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-light);

    @media (max-width: 900px) {
      padding: 16px 16px 68px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: 16px;
    span {
      @include heading_three;
    }
  }

  &__list {
    display: flex;

    &-item {
      width: 150px;
      display: flex;
      flex-direction: column;

      &:not(&:last-child) {
        margin-right: 48px;
      }
    }

    &-item-type {
      margin-bottom: 2px;
      span {
        @include subtitle;
        color: var(--gray-thirdy);
      }
    }

    &-item-name {
      margin-bottom: 2px;

      span {
        font-size: 12px !important;
      }
    }

    &-item-amount {
      span {
        @include heading_three;
        color: var(--black-primary);
      }
    }
  }
}

.accepted-offers-waiting {
  .accepted-offers-waiting__list {
    padding: 24px 0;

    @media (max-width: 900px) {
      padding: 0 0 24px;
    }
  }
}
.accepted-offers-inprogress-mobile {
  padding-top: 24px;

  &__title {
    @include heading_three;
  }

  .accepted-offers-inprogress-mobile__list {
    padding: 16px 0;

    @media (max-width: 900px) {
      padding: 16px 0 24px;
    }
  }
}
