.personal-info {
  display: flex;
  flex-wrap: nowrap;

  &__header {
    display: none;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;

      height: 56px;
      padding: 8px 16px;
      margin-bottom: 38px;

      background-color: var(--gray-primary);
      color: var(--white);

      svg {
        width: 160px;
      }
    }

    > div {
      width: 100%;
    }
  }

  &__content {
    //   display: flex;
    //   justify-content: center;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    // min-width: 320px;
    // max-width: 320px;
    padding-top: 16px;
    gap: 0 16px;
    max-width: 656px;

    // .form-input:nth-child(1) {
    //   order: 1;
    // }
    // .form-input:nth-child(2) {
    //   order: 3;
    // }
    // .form-input:nth-child(3) {
    //   order: 2;
    // }
    // .form-input:nth-child(4) {
    //   order: 4;
    // }

    .form-input {
      width: 100% !important;
      margin-bottom: 16px;
      @media (min-width: 767px) {
        max-width: 320px;
      }
    }

    &-inner {
    }

    &-btn {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
