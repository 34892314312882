@mixin fontSemiBold {
  font-family: 'Inter Semi Bold';
  font-style: normal;
  letter-spacing: -0.02em;
}

@mixin fontRegular {
  font-family: 'Inter Regular';
  font-style: normal;
  letter-spacing: -0.02em;
}

@mixin heading_one {
  @include fontSemiBold;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

@mixin heading_two {
  @include fontSemiBold;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

@mixin heading_three {
  @include fontSemiBold;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

@mixin heading_four {
  @include fontRegular;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@mixin heading_five {
  @include fontSemiBold;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@mixin heading_six {
  @include fontSemiBold;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

@mixin paragraph {
  @include fontRegular;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin subtitle {
  @include fontRegular;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
