.custom-tab {
  display: flex;
  //   width: max-content;
  position: relative;
  margin-top: 8px;
  @media (max-width: 900px) {
    margin: 0 -16px;
    // width: calc(100% + 32px) !important;
    width: 100vw !important;
  }
  @media (max-width: 767px) {
    // width: calc(100% + 56px) !important;
    width: 100vw !important;
  }
  &::before {
    content: ' ';
    background-color: var(--lgray-primary);
    // width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    z-index: 20;
  }

  @media (max-width: 991px) {
    width: 100%;
    overflow-y: auto;
  }

  &__item {
    font-weight: 400;
    font-size: 14px;
    &::before {
      content: ' ';
      background-color: var(--lgray-primary);
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0px;
      // z-index: 23;
      right: 0;
    }
  }

  &__item,
  &__item-active {
    letter-spacing: -0.02em;
    display: inherit;
    padding: 0 8px;
    cursor: pointer;
    position: relative;
    // border-bottom: 1px solid var(--lgray-primary);
    padding-bottom: 12px;
    // font-size: 12px;
    line-height: 20px;
    // font-weight: 600;
    display: flex;
    align-items: center;
    min-width: 155px;
    white-space: nowrap;
    justify-content: center;
    color: var(--gray-thirdy);
    &-active {
      @include heading_five;
      position: relative;
      color: var(--violet-primary);
      &::after {
        content: ' ';
        background: var(--violet-primary);
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0px;
        z-index: 21;
        right: 0;
      }
    }
    &:last-child {
    }
    &-icon {
      padding-left: 4px;
    }
    &__cyrcle {
      display: flex;
      background: var(--violet-primary);
      color: var(--white);
      border-radius: 10px;
      height: max-content;
      padding: 3px 4px;
      font-size: 12px;
      height: 20px;
      align-items: center;
      text-align: center;
      max-width: inherit;
      min-width: 20px;
      justify-content: center;
    }
    &__cyrcle2 {
      border: 1px solid var(--violet-primary);
      border-radius: 10px;
      height: 20px;
      font-size: 12px;
      color: var(--violet-primary);
      max-width: inherit;
      min-width: 20px;
      text-align: center;
      padding: 0 3px;
    }
  }
  &__item {
    &__cyrcle {
      background: var(--white);
      border: 1px solid var(--gray-thirdy);
      color: var(--gray-thirdy);
    }
  }
  &__item-active {
    .custom-tab__item__cyrcle {
      background: var(--violet-primary);
      color: var(--white);
      border: 1px solid transparent;
    }

    .custom-tab__item-icon {
      svg {
        path {
          stroke: var(--violet-primary);
        }
      }
    }
  }
  &__content {
    @media (min-width: 900px) {
      padding-top: 8px;
    }
    &-item {
      display: none;
    }
    &-activeItem {
      display: block;
    }
  }
  &__disabled {
    opacity: 0.5;
    .custom-tab__item {
      cursor: default;
      pointer-events: none;
    }
  }
}

.test {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
  }
  #nav {
    display: table;
    table-layout: fixed;
    text-align: center;
  }
  #nav li {
    display: table-cell;
    // width: 25%;
    padding-right: 1px;
    height: auto;
    vertical-align: bottom;
  }
}
