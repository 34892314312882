.status-wrapper {
  display: inline-block;
  .status {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 4px;
    border-radius: 20px;
    overflow: hidden;

    span {
      @include subtitle;
      display: flex;
      margin-left: 4px;
    }

    &.not_answered,
    &.waiting_for_answer {
      background: var(--yellow-thirdy);

      span {
        color: var(--yellow-primary);
      }

      svg path {
        stroke: var(--yellow-primary);
      }
    }

    &.completed {
      background: var(--green-secondary);

      span {
        color: var(--green-success);
      }

      svg path {
        stroke: var(--green-success);
      }
    }

    &.declined,
    &.canceled {
      background: var(--red-thirdy);

      span {
        color: var(--red-primary);
      }

      svg path {
        stroke: var(--red-primary);
      }
    }

    &.nulled {
      background: var(--lgray-primary);

      span {
        color: var(--gray-primary);
      }

      svg path {
        stroke: var(--gray-primary);
      }
    }

    &.in_progress,
    &.new {
      background: var(--violet-thirdy);

      span {
        color: var(--violet-primary);
      }

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &.new_order {
      background: var(--blue-secondary);

      span {
        color: var(--blue-primary);
      }

      svg path {
        stroke: var(--blue-primary);
      }
    }
  }
}
