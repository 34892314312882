.analytics {
  &-content {
    border-radius: 8px;
    padding: 16px;
    background-color: var(--white);
    margin-top: 16px;
    overflow: hidden;
    box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);

    &__container-chart {
      padding-left: 40px;
      position: relative;
    }
    &-title {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .select-wrap {
        width: 200px;
        height: 28px;
        @media (max-width: 900px) {
          width: 155px;
        }
        [class$='-control'] {
          height: 28px;
          min-height: 28px;
          border-color: var(--gray-thirdy) !important;
          border-radius: 8px !important;
        }
        [class$='-singleValue'] {
          color: var(--gray-thirdy) !important;
        }

        [class$='-IndicatorsContainer'] {
          position: relative;
          top: -2px;

          @media (max-width: 900px) {
            top: -0px;

            > div {
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: -8px;

              svg {
                display: block !important;
              }
            }
          }

          svg {
            path {
              stroke: var(--gray-thirdy) !important;
            }
          }
        }
        [class$='-ValueContainer'] {
          top: -1px;
          position: relative;
        }
        [class$='-menu'] {
          top: 28px !important;
        }
        [class$='-MenuList'] {
          box-shadow: var(--shadow-light);
        }
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      margin-bottom: 27px;
      align-items: center;
      .project-page__header {
        margin-bottom: 0;
      }
    }
    h1 {
      padding-top: 10px;
      @media (max-width: 900px) {
        padding-top: 4px;
      }
    }
  }
  &__content-card {
    display: flex;
    gap: 16px;
    @media (max-width: 900px) {
      flex-wrap: wrap;
      margin-bottom: 8px;
    }
  }
  &__card {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    &-title {
      @include paragraph;
      color: var(--gray-primary);
      display: flex;
      align-items: center;
      max-width: 136px;
    }

    &-description {
      // UI Colors/Black-Primary
      color: var(--black-primary);
      @include heading_three;
      align-items: center;
      display: flex;
    }

    &-icon {
      margin-right: 8px;
      padding: 10px;
      background-color: var(--violet-thirdy);
      border-radius: 50%;
      svg {
        display: block !important;
      }
      path {
        stroke: var(--violet-primary);
        // background-color: var(--violet-primary);
      }
      // display: flex;
      // flex-direction: row;
    }
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);
    border-radius: 8px;
    width: 33.33%;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  // min-height: 540px;
  // height: 300px;
  width: 100%;
  // background-color: #fff;
  padding: 20px;

  @media (max-width: 900px) {
    padding: 0px !important;
  }

  &-chart {
    @media (max-width: 900px) {
      min-height: 360px;
      // height: 340px;

      // overflow-y: hidden;
      // overflow-x: auto;
    }
    // left: 40px;
    height: 300px;
    position: relative;
    // width: calc(100% - 40px);
    &-info {
      position: relative;
      left: -40px;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
      @media (max-width: 900px) {
        // left: 0;
        width: calc(100% + 40px);
        margin-top: 15px;
        .analytics-chart-info-title {
          width: 50%;
          margin-bottom: 8px;
        }
      }
      :first-child {
        .analytics-chart-info-color {
          background-color: var(--violet-primary);
        }
      }
      :nth-child(2) {
        .analytics-chart-info-color {
          background-color: var(--green-success);
        }
      }
      :nth-child(3) {
        .analytics-chart-info-color {
          background-color: var(--yellow-secondary);
        }
      }

      &-color {
        margin-right: 6px;
        min-width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: var(--red-secondary);
      }
      &-title {
        padding-right: 24px;
        display: flex;
        align-items: center;
        @include subtitle;
      }
    }
    &-legend {
      @include subtitle;
      color: var(--gray-thirdy);
      height: 360px;

      @media (max-width: 900px) {
        height: 320px;
      }

      position: absolute;
      z-index: 1;
      // justify-content: space-between;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 6.5px;
      padding-bottom: 15.5px;
      left: 0px;
      :nth-child(1) {
        bottom: 24px;
        position: relative;
        @media (max-width: 900px) {
          bottom: -14px;
        }
      }
      :nth-child(2) {
        bottom: 55px;
        position: relative;
        @media (max-width: 900px) {
          bottom: 24px;
        }
      }
      :nth-child(3) {
        bottom: 86px;
        position: relative;
        @media (max-width: 900px) {
          bottom: 64px;
        }
      }
      :nth-child(4) {
        bottom: 118px;
        position: relative;
        @media (max-width: 900px) {
          bottom: 102px;
        }
      }
      :nth-child(5) {
        bottom: 149px;
        position: relative;
        @media (max-width: 900px) {
          bottom: 141px;
        }
      }
      :nth-child(6) {
        bottom: 180px;
        position: relative;
      }
    }
    line {
      stroke: var(--lgray-primary);
    }
    path.recharts-curve.recharts-line-curve {
      stroke-width: 2;
    }
  }
  &-title {
    @include heading_two;
    margin-bottom: 0;
    margin-top: 0;
  }
  .recharts-wrapper {
    // margin-top: 47px;
    overflow: hidden;
    @media(min-width: 900px) {
      position: unset;
      overflow: unset;
    }
  }
  .recharts-surface {
    // margin-top: 47px;
    background-color: #fff;
  }
  line.recharts-cartesian-axis-line {
    display: none;
  }
  line.recharts-cartesian-axis-tick-line {
    display: none;
  }
  ul.recharts-default-legend {
    text-align: start !important;
  }
  //#F5F6F9
  .recharts-tooltip-wrapper {
    z-index: 1;
    border: 0px;
    outline: none;
    position: absolute;
    // width: ;
  }
  path.recharts-curve.recharts-tooltip-cursor {
  }

  g.recharts-layer.recharts-cartesian-axis-tick text {
    y: 290;
  }

  .underTooltip {
    width: 8px;
    height: 8px;
    background: #fff;
    transform: rotate(315deg);
    flex: none;
    order: 1;
    flex-grow: 0;
    right: calc(-50% + 3.5px);
    position: relative;
    bottom: -7.5px;
  }
  .tooltip {
    .title {
      @include subtitle;
      color: var(--black-primary);
      margin: 0;
      padding-left: 16px;
      padding-bottom: 4px;
    }
    cursor: none;
    // transform: translate(-50%, -105%);
    background-color: #fff;
    border-radius: 8px;
    outline: none !important;
    box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14) !important;
    z-index: 111;
  }
  .custom-tooltip {
    padding-top: 16px;
    padding-bottom: 4px;
    box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14) !important;

    .label {
      @include subtitle;
      color: var(--monchrome-wf-600);
      display: flex;
      align-items: center;
      margin: 0;
      padding-right: 16px;
      padding-bottom: 4px;
      padding-left: 16px;
      padding-top: 0;
    }
    :first-child {
      .color {
        background-color: var(--violet-primary);
      }
    }
    :nth-child(2) {
      .color {
        background-color: var(--violet-primary);
      }
    }
    :nth-child(3) {
      .color {
        background-color: var(--green-success);
      }
    }
    :nth-child(4) {
      .color {
        background-color: var(--yellow-secondary);
      }
    }

    .color {
      margin-right: 6px;
      min-width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: var(--red-secondary);
    }
  }

  // path.recharts-curve.recharts-tooltip-cursor {
  //     height: 30px;
  //     background: red;
  //     color: red;
  //     stroke: red;
  //     d: path("M 405 154 L 405 246.333");
  // }

  g.recharts-layer.recharts-cartesian-axis-tick {
    tspan {
      @include subtitle;
      fill: var(--gray-thirdy);
    }
  }

  g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis {
    // display: none;
  }
}


// .recharts-wrapper {
//   overflow-x: scroll;
//   position: relative;
//   scrollbar-color: #E4E8EE #A7AEBA;
//   scrollbar-width: thin;
//   -ms-overflow-style: none;
// }

// .pseduo-track {
//   background-color: #A7AEBA;
//   height: 3px;
//   width: calc(100% - 46px);
//   position: absolute;
//   z-index: 32323;
//   opacity: 0.3;
//   border-radius: 10px;
//   bottom: 71px;
//   left: 43px;
// }

// @media(max-width: 374px) {
//   .pseduo-track {
//     display: none;
//   }
// }

// // @media (any-hover: none) {
// //   .pseduo-track {
// //     display: none;
// //   }
// // }

// .recharts-wrapper::-webkit-scrollbar {
//   height: 3px;
// }

// .recharts-wrapper::-webkit-scrollbar-track {
//   // -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
//   border-radius: 10px;
// }

// .recharts-wrapper::-webkit-scrollbar-thumb {
//   height: 3px;
//   // background-color: #E4E8EE;
//   background-color: red;;
//   border-radius: 10px;
// }

// .recharts-wrapper::webkit-scrollbar-corner {
//   background: red;
// }

// .recharts-wrapper::-webkit-scrollbar-thumb:hover {
//   // background-color: #A7AEBA;
//   background-color: red;;
//   border-radius: 10px;
// }

// .recharts-wrapper::-webkit-scrollbar:vertical {
//   display: none;
// }
.track-horizontal {
  position: absolute;
  height: 4px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
  display: unset!important;
  background-color: #E4E8EE;
  z-index: 9;
}

.thumb-horizontal {
    position: relative;  
    display: block;
    height: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: #A7AEBA;
    // opacity: 0.5;
    width: 50px;
}
@media(max-width: 900px) {
.analytics-chart,
.analytics-chart > div {
  -ms-content-zooming:none;  
  touch-action: none;
  content-zooming: none;
  overflow-y: hidden!important; // hide vertical
  -webkit-overflow-scrolling:touch
}
}
@media(min-width: 900px) {
  .analytics-content__container-chart {
    position: relative;
    // top: -40px;
    margin: -30px 0;
  }
}