.nav-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__item {
    cursor: pointer;
    margin-bottom: 16px;

    &-title {
      @include heading_four;
    }

    a,
    > div {
      position: relative;
      display: flex;
      align-items: center;

      padding: 12px 12px;
      border-radius: 8px;
      color: var(--white);
      text-decoration: none;
    }

    a:hover,
    > div:hover {
      opacity: 0.8;
    }

    > div {
      position: relative;
      display: flex;
      align-items: center;
    }

    a:focus-visible {
      outline: none !important;
      opacity: 0.7;
    }
  }

  svg path {
    stroke: var(--white);
  }

  &__sublist {
    max-height: 0;
    padding: 0px 12px 0px 0px !important;
    overflow: hidden;
    transition: all 0.2s ease;

    &.expanded {
      max-height: 100vh;
      padding: 0px 12px 0px 0px !important;
    }

    span {
      @include paragraph;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      flex-shrink: 1;
      color: var(--lgray-primary);
    }

    li {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-bottom: 0 !important;
      position: relative;
      padding-left: 27px;

      &.active:before {
        content: '';
        position: absolute;
        left: 18px;
        top: 18px;
        width: 8px;
        height: 8px;
        @media (max-width: 900px) {
          background: var(--gray-secondary);
        }
        background: #fff;
        border-radius: 50%;
        z-index: 111111;
      }
    }
  }

  .icon.sublist-open > svg {
    transform: rotate(-90deg);
  }

  span:not(.icon) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    flex-grow: 1;
    flex-shrink: 1;
  }

  span.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    svg {
      transition: all 0.2s ease;
    }

    &:last-child {
      margin-right: 0px;

      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
