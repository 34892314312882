.actions {
  position: relative;

  svg {
    cursor: pointer;
  }

  &__bg {
    display: none;
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    background: var(--black-primary);
    opacity: 36%;

    @media (max-width: 900px) {
      display: block;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 311;

    min-width: 102px;

    @media (min-width: 901px) {
        min-width: 155px;
    }

    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-medium);

    animation: actionsShowAimation 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9)
      forwards;

    -webkit-animation: actionsShowAimation 0.2s
      cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @media (max-width: 900px) {
      position: fixed;
      top: unset;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-item {
      display: flex;
      flex-wrap: nowrap;
      padding: 8px 16px;
      align-items: center;

      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid var(--lgray-primary);
      }

      @media (max-width: 900px) {
        justify-content: center;
        padding: 16px;

        span {
          @include paragraph;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-btn {
      margin-right: 8px;
      justify-content: center;
      display: flex;

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &-desc {
      @include subtitle;
      color: var(--gray-primary);
    }
  }
}

@keyframes actionsShowAimation {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: var(--shadow-medium);
  }
}
