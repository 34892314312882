.dropdown {
  .label-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .label {
      @include subtitle;
      display: block;
      color: var(--black-primary);

      &:first-letter {
        text-transform: uppercase;
      }

      &.error {
        color: var(--gray-primary);
      }
    }
  }

  [class$='-singleValue'] {
    font-size: 14px !important;
  }

  @media (max-width: 768px) {
    [class$='-singleValue'] {
      font-size: 16px !important;
    }

    [class$='-Placeholder'] {
      font-size: 16px !important;
    }
  }

  .select-wrap {
    position: relative;
    margin-bottom: 2px;

    &.error {
      .dropdown_input {
        > span + div {
          border: 1px solid var(--red-secondary) !important;
        }
      }
    }
  }

  p.errorName {
    @include subtitle;
    margin: 2px 0 0;
    width: 100%;
    text-align: left;
    color: var(--red-secondary);
  }

  &.disabled {
    .label {
      color: var(--gray-primary);
    }

    [class$='-control'] {
      background: #f1f0f3;
      svg {
        path {
          stroke: var(--gray-thirdy) !important;
        }
      }
    }

    input {
      background: #f1f0f3;
      border: 1px solid var(--gray-thirdy);
      box-shadow: none !important;
      &::placeholder {
        color: var(--gray-thirdy);
      }
    }
  }
}
