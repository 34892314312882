.notification-popup {
  position: relative;
  cursor: auto;

  &__area {
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 110;
    width: 100%;
    height: 100%;
    background: var(--black-primary);
    opacity: 36%;
  }

  &__inner {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    left: 224px;
    top: 96px;
    z-index: 111;

    width: 356px;
    height: 412px;
    background: transparent;
    opacity: 0;

    &.animation {
      animation: notificationsShowAimation 0.2s
        cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

      -webkit-animation: notificationsShowAimation 0.2s
        cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    }

    &.animation-hide {
      animation: notificationsHideAimation 0.1s
        cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

      -webkit-animation: notificationsHideAimation 0.1s
        cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    }
  }
  &__content {
    width: 348px;
    height: 100%;
    border-radius: 8px;

    position: relative;
    background: var(--white);
    box-shadow: var(--shadow-hard);

    &:before {
      content: '';
      position: absolute;
      top: 16px;
      left: -13px;
      border-color: transparent var(--white) transparent transparent;
      border-width: 8px 13px 8px 0px;
      border-style: solid;
    }
  }

  .notification-list {
    height: 351px;
    overflow: hidden;
    // padding-top: 24px;

    &.not-apple {
      @media (min-width: 900px) {
        ::-webkit-scrollbar,
        ::-webkit-scrollbar-corner {
          height: 16px;
          width: 16px;
          background: transparent;
          cursor: pointer;
        }

        ::-webkit-scrollbar-thumb,
        ::-webkit-scrollbar-thumb {
          background: content-box currentColor;
          border: 6px solid transparent;
          border-radius: 8px;
          color: #dadce0;
          min-height: 60px;
          min-width: 60px;

          cursor: pointer;
        }
      }
    }

    > div {
      padding-top: 24px;
      height: 100%;
    }
    > div > div {
      width: 100% !important;
      // width: 105%;
      // padding-right: 5%;
      // box-sizing: content-box;
    }

    &.apple-fix {
      > div > div {
        width: 100% !important;
      }
    }

    .notification-list__inner {
      //  overflow-y: auto;
      // height: 100%;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 1px;

      padding: 16px;
      border-bottom: 1px solid var(--lgray-primary);

      &:first-child {
        padding: 0px 16px 16px 16px;
      }

      &:last-child {
        border-bottom: none;
      }

      .link:hover {
        text-decoration: none !important;
      }

      span {
        @include subtitle;

        &:not(.link) {
          color: var(--black-primary);
        }
      }
    }

    &__text {
      position: relative;
      display: flex;
      flex-wrap: nowrap;

      width: 100%;
      justify-content: space-between;

      > span:nth-child(1) {
        margin-right: 8px;
      }

      svg {
        display: block;
        position: relative;
        top: -2px;
        cursor: pointer;
      }

      // &:after {
      //   content: '';
      //   position: absolute;
      //   width: 8px;
      //   height: 8px;

      //   right: 0;
      //   top: 0;

      //   border-radius: 50%;
      //   background-color: var(--violet-primary);
      // }

      &.not-read span {
        @include heading_six;
        line-height: 18px;
      }
    }

    &__date {
      display: block;
      margin-top: 4px;
      color: var(--gray-primary) !important;
    }
  }

  .empty-list {
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 100px;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--violet-thirdy);

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &__title {
      @include paragraph;
      color: var(--gray-primary);
    }
  }

  .notification-delete {
    height: 52px;
    width: 100%;
    margin-top: 8px;
    padding: 16px;
    box-shadow: var(--shadow-light-top);

    &__inner {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }

    svg {
      display: block !important;
      path {
        stroke: var(--violet-primary);
      }
    }
    span {
      @include paragraph;
      color: var(--gray-primary);
      display: block;
      margin-left: 10px;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    overflow: hidden;

    position: absolute;
    right: -30px;
    top: 0;

    cursor: pointer;

    box-shadow: var(--shadow-hard);
    background: var(--white);
    border-radius: 50%;

    > svg path {
      stroke: var(--gray-secondary);
    }
  }
}

@keyframes notificationsShowAimation {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes notificationsHideAimation {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  50% {
    transform: scale(0.96) translateY(10px);
    opacity: 0.5 !important;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 0 !important;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
}
