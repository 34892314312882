.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  cursor: pointer;
  outline: none;
  box-sizing: border-box;

  &__icon {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 4px;
    width: 16px;
    height: 16px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      margin-left: 0;
    }
  }

  &.big {
    @include paragraph;
    padding: 16px 24px;
    border-radius: 0px 0px 8px 8px;
    @media(max-width: 400px) {
      padding: 16px 4px;
    }
  }

  &.medium {
    @include paragraph;
    padding: 12px 24px;
  }

  &.large {
    @include paragraph;
    padding: 12px 58px;
  }

  &.small {
    @include subtitle;
    padding: 8px 24px;
  }

  &.add_small {
    @include subtitle;
    height: 44px;
    width: 44px;
  }

  &.primary {
    background: var(--violet-primary);
    color: var(--white);
    border: 2px solid transparent;

    &:hover {
      background: var(--violet-thirdy);
      color: var(--violet-primary);

      svg {
        path {
          stroke: var(--violet-primary);
        }
      }
    }

    &:focus {
      border: 2px solid var(--violet-thirdy);
    }

    &:active {
      background: var(--violet-primary);
      border: 2px solid transparent;
      color: var(--white);

      svg {
        path {
          stroke: var(--white);
        }
      }
    }

    svg {
      path {
        stroke: var(--white);
      }
    }
  }

  &.secondary {
    background: var(--violet-secondary);
    color: var(--violet-primary);
  }

  &.outline {
    background: var(--white);
    color: var(--violet-primary);
    border: 1px solid #4446b0;

    &:disabled {
      border: 1px solid transparent !important;
    }

    &:hover {
      background: var(--violet-thirdy);
      border: 1px solid transparent;
      color: var(--violet-primary);
    }

    &:focus {
      border: 2px solid var(--violet-thirdy);
    }

    &:active {
      background: var(--white);
      color: var(--violet-primary);
      border: 1px solid #4446b0;
    }

    svg {
      path {
        stroke: var(--violet-primary);
      }
    }
  }

  &.tertiary {
    background: var(--lgray-primary);
    color: var(--black-primary);
    border-color: var(--lgray-primary);

    svg {
      path {
        stroke: var(--black-primary);
      }
    }

    &.big,
    &.medium {
      background: var(--lgray-primary);
      color: var(--black-primary);
    }

    &.small {
      background: var(--lgray-secondary);
    }

    &.big:hover,
    &.medium:hover {
      background: var(--gray-thirdy);
      border-color: var(--gray-thirdy);
    }

    &.small:hover {
      background: var(--lgray-primary);
    }

    &.big:focus {
      background: var(--lgray-primary);
      border: 2px solid var(--violet-thirdy);
    }

    &.medium:focus,
    &.small:focus {
      border: 2px solid var(--violet-thirdy);
      background: var(--lgray-secondary);
    }

    &.big:active,
    &.medium:active {
      background: var(--lgray-primary);
      border: 2px solid transparent;
    }

    &.big.detail_role {
      background: var(--lgray-primary);
    }

    &.medium.detail_role,
    &.small.detail_role {
      background: var(--lgray-secondary);
    }

    &.big.detail_role:hover,
    &.medium.detail_role:hover,
    &.small.detail_role:hover {
      background: var(--lgray-primary);
    }

    &.big.detail_role:focus,
    &.medium.detail_role:focus,
    &.small.detail_role:focus {
      background: var(--lgray-secondary);
      border: 2px solid var(--violet-thirdy);
    }

    &.big.detail_role:active {
      background: var(--lgray-primary);
      border: 2px solid transparent;
    }

    &.medium.detail_role:active,
    &.small.detail_role:active {
      border: 2px solid transparent;
      background: var(--lgray-secondary);
    }
  }

  &:disabled {
    background: var(--lgray-primary) !important;
    border-color: var(--lgray-primary) !important;
    color: var(--gray-thirdy) !important;

    cursor: auto !important;

    svg {
      path {
        stroke: var(--gray-thirdy) !important;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: var(--lgray-primary) !important;
      color: var(--gray-thirdy) !important;

      svg {
        path {
          stroke: var(--gray-thirdy) !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.add_role {
      &.hide-text {
        @media (max-width: 768px) {
          .btn__text {
            display: none;
          }
        }
      }
    }
  }
}
