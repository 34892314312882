.project-page {
  .container_title path {
    stroke: var(--black-primary);
    h1.title div {
      padding-right: 8px;
    }
  }
  &__header {
    margin-bottom: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      margin-bottom: 16px;
    }

    &-title {
      @include heading_one;
      color: var(--black-primary);
    }
  }

  &__list {
    padding: 16px 0 24px;

    &-item:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--violet-thirdy);

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &__title {
      @include paragraph;
      color: var(--gray-primary);
    }
  }
}

.sa-projects {
  .back-icon {
    svg {
      path {
        stroke: var(--gray-primary);
      }
    }
  }
}
