.project-item {
  &__inner {
    display: flex;
    flex-wrap: nowrap;
    // align-items: center;
    position: relative;

    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-light);

    @media (max-width: 900px) {
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__name {
    width: 41.66%;
    cursor: pointer;

    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 16px;
    }

    &-project {
      @include heading_three;

      display: block;
      margin-bottom: 2px;

      color: var(--black-primary);
    }

    &-role {
      @include subtitle;
      color: var(--gray-thirdy);
    }
  }

  &__info {
    @include subtitle;
    display: flex;
    width: 58.33%;

    @media(min-width: 901px) {
      grid-gap: 9px 0;
    }
    // @media (max-width: 900px) {
      width: 100%;
      flex-direction: column;

      &-block:nth-child(1) {
        padding-top: 0px;
        order: 1;
      }
      &-block:nth-child(2) {
        order: 3;
      }
      &-block:nth-child(3) {
        order: 2;
      }
      &-block:nth-child(4) {
        order: 4;
      }
    // }

    @media (min-width: 901px) {
      display: grid;
      grid-template-columns: repeat(2, 249px);
    }

    &-block {
      display: flex;
      flex-direction: column;
      // width: 33.33%;
      width: 241px;
      padding-right: 16px;

      @media (max-width: 900px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0px;
        padding-top: 8px;
      }
    }

    &-title {
      color: var(--gray-thirdy);
    }
    &-desc {
      color: var(--black-primary);
    }
  }

  &__actions {
    position: absolute;
    right: 24px;
    top: 24px;

    cursor: pointer;

    @media (max-width: 900px) {
      right: 16px;
      top: 16px;
    }
  }
}
