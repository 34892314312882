.company-order-item {
    &__inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
  
      padding: 24px;
      border-radius: 8px;
      background: var(--white);
      box-shadow: var(--shadow-light);
  
      @media (max-width: 900px) {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  
    &__name {
      width: 41.66%;
      @media(min-width: 767px) {
        min-width: 250px;
        max-width: 450px;
      }
      &-name {
        @include heading_five;
      }
  
      @media (max-width: 900px) {
        width: 100%;
        margin-bottom: 16px;
      }
  
      &-company-order {
        @include heading_three;
  
        display: block;
        margin-bottom: 2px;
  
        color: var(--black-primary);
      }
  
      &-count {
        padding-top: 8px;
        // @include subtitle;
        @include heading_two;
        // color: var(--gray-thirdy);
      }
    }
  
    &__info {
      @include subtitle;
      display: flex;
      width: 68.33%;
  
      @media (max-width: 900px) {
        width: 100%;
        flex-direction: column;
      }
  
      &-block {
        display: flex;
        flex-direction: column;
        width: 33.33%;
        padding-right: 16px;
  
        @media (min-width: 767px) and (max-width: 900px) {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 0px;
        }
        @media (max-width: 767px) {
          width: 100%;
          justify-content: space-between;
          padding-right: 0px;
        }
      }
  
      &-title {
        color: var(--gray-thirdy);
      }
      &-desc {
        padding-top: 2px;
        @include heading_six;
        color: var(--violet-primary);
      }
    }
  
    &__actions {
      position: absolute;
      right: 24px;
      top: 24px;
  
      cursor: pointer;
  
      @media (max-width: 900px) {
        right: 16px;
        top: 16px;
      }
    }
  }
  // 
  .company-order-in-progress-item {
    &__inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
  
      padding: 24px;
      border-radius: 8px;
      background: var(--white);
      box-shadow: var(--shadow-light);
  
      @media (max-width: 900px) {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  
    &__box {
      display: flex;
      flex-wrap: wrap;
    }

    &__name {
      width: 100%;
      @media(min-width: 767px) {
        // min-width: 250px;
        // max-width: 450px;
      }
      &-name {
        @include heading_five;
        @media(max-width: 900px) {
          margin-bottom: 8px;
        }
      }
  
      // @media (max-width: 900px) {
      //   width: 100%;
      //   margin-bottom: 16px;
      // }
  
      &-company-order {
        @include heading_three;
  
        display: block;
        margin-bottom: 2px;
  
        color: var(--black-primary);
      }
  
      &-count {
        padding-top: 8px;
        // @include subtitle;
        @include heading_two;
        // color: var(--gray-thirdy);
      }
    }
  
    &__info {
      @include subtitle;
      display: flex;
      width: 33.33%;
      @media (max-width: 1199px) {
        width: 100%;
      }
  
      @media (max-width: 900px) {
        width: 100%;
        flex-direction: column;
      }
  
      &-block {
        display: flex;
        flex-direction: column;
        // width: 33.33%;
        padding-right: 16px;
        @media(min-width: 900px) {
          margin-top: 16px;
        }
  
        // @media (min-width: 767px) and (max-width: 900px) {
        //   width: 100%;
        //   flex-direction: row;
        //   justify-content: space-between;
        //   padding-right: 0px;
        // }
        @media (max-width: 767px) {
          width: 100%;
          justify-content: space-between;
          padding-right: 0px;
        }
      }
      &-link {
        @include heading_six;

      }
      &-amount {
        @include heading_three;
        color: #1A202C;
      }
      &-title {
        color: var(--gray-thirdy);
      }
      &-desc {
        padding-top: 4px;
        padding-bottom: 4px;
        @include heading_six;
        color: var(--violet-primary);
      }
    }
  
    &__actions {
      position: absolute;
      right: 24px;
      top: 24px;
  
      cursor: pointer;
  
      @media (max-width: 900px) {
        right: 16px;
        top: 16px;
      }
    }
  }