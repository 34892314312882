.offer-card-customer {
  &__inner {
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-light);

    @media (max-width: 900px) {
      padding: 16px 16px 68px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__name {
    width: 50%;
    @media (min-width: 767px) {
      min-width: 250px;
      max-width: 450px;
    }
    &-name {
      @include heading_five;
    }

    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 16px;
    }

    &-company-order {
      @include heading_three;

      display: block;
      margin-bottom: 2px;

      color: var(--black-primary);
    }

    &-count {
      padding-top: 8px;
      // @include subtitle;
      @include heading_two;
      // color: var(--gray-thirdy);
    }
  }

  &__info {
    @include subtitle;
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }

    @media (min-width: 1500px) {
      width: 100% !important;
    }

    &-block {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (max-width: 900px) {
        &.offer-btn {
          margin-top: 16px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          button {
            width: 100%;
            margin: 0px !important;
          }
        }
      }

      &.offer-btn {
        margin-top: 16px;

        button {
          margin: 0px !important;

          @media (min-width: 900px) {
            max-width: 292px;
            align-self: flex-start;
          }

          @media (min-width: 1500px) {
            align-self: flex-end;
          }

          width: 100%;

          &:disabled {
            cursor: auto;
          }
        }
      }

      @media (min-width: 767px) and (max-width: 900px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0px;
      }
      @media (max-width: 767px) {
        width: 100%;
        justify-content: space-between;
        padding-right: 0px;
      }
    }

    &-title {
      color: var(--gray-thirdy);
    }
    &-desc {
      padding-top: 2px;
      @include heading_six;
      color: var(--violet-primary);
    }
  }

  &__actions {
    position: absolute;
    right: 24px;
    top: 24px;

    cursor: pointer;

    @media (max-width: 900px) {
      right: 16px;
      top: 16px;
    }
  }

  &.accepted {
    @media (max-width: 900px) {
      .offer-card-customer__inner {
        flex-direction: column-reverse;
      }

      .offer-card-customer__name {
        margin-bottom: 0;
      }

      .offer-card-customer__name-name {
        @include subtitle;
        color: var(--gray-thirdy);
      }

      .offer-card-customer__name-count {
        @include heading_six;
        padding-top: 2px;
      }

      .offer-card-customer__info-title {
        @include heading_five;
        color: var(--black-primary);
      }

      .offer-card-customer__info-desc.link {
        @include heading_two;
        padding-top: 8px;
        margin-bottom: 16px;
      }
    }
  }

  &.done {
    @media (max-width: 900px) {
      .offer-card-customer__inner {
        padding: 16px !important;
      }

      .offer-card-customer__name {
        margin-bottom: 16px;
      }
    }
  }
}
