.order-card-supplier {
  padding: 24px;
  border-radius: 8px;
  position: relative;

  overflow: hidden;
  background: var(--white);
  box-shadow: var(--shadow-light);

  .declied-btn-container {
    @media (max-width: 900px) {
      margin: 0 -16px;
      width: calc(100% + 32px);
      button {
        width: 100% !important;
      }
    }
  }
  &__desc-col {
    .link {
      @include heading_six;
    }
  }

  .status-wrapper {
    @media (max-width: 900px) {
      margin-bottom: 8px;
    }
  }

  .edited-label {
    color: var(--gray-primary);
    @include paragraph;
    display: block;
    margin-left: 8px;
    margin-top: 3px;
  }

  &-description {
    &__body {
      display: flex;
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    padding: 16px 16px 0px;
  }

  &.horizontal.tender {
    padding: 24px;
  }

  &.tender {
    padding: 24px 24px 84px;

    @media (max-width: 900px) {
      padding: 24px 24px 61px;
    }
  }
  &__status {
    display: flex;
    align-items: flex-start;
  }
  &__header {
    // min-width:
    min-width: 292px;
    max-width: 292px;

    @media (min-width: 1500px) {
      min-width: 380px;
      max-width: 380px;
    }
  }
  &__body {
    width: inherit;
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: 900px) {
      display: block;
      margin-bottom: 0;
      span {
        padding-bottom: 2px;
      }
    }
  }
  &__footer {
    display: flex;

    &-error {
      @include heading_five;
      // color:  var(--red-secondary);
      @media (max-width: 900px) {
        margin-top: 8px;
      }
    }

    &-accept {
      @include heading_five;
      color: var(--green-accept);
      // text-transform: lowercase;
      span {
        text-transform: lowercase;
        @include heading_five;
        color: var(--green-accept);
      }
      @media (max-width: 900px) {
        margin-top: 8px;
      }
    }
    &-count {
      @include heading_five;
      color: var(--black-primary);

      @media (max-width: 900px) {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--gray-primary);
      align-self: center;
      @media (max-width: 900px) {
        display: none;
      }
    }

    &-btns {
      margin-top: 8px;
      display: flex;
      &.nulled {
        button {
          border: none;
          background-color: var(--lgray-secondary) !important;
          @media (max-width: 767px) {
            background-color: var(--lgray-primary) !important;
          }
          @media (max-width: 900px) {
            width: 100%;
          }
        }
      }
      @media (max-width: 900px) {
        width: calc(100% + 32px);
        margin: 0 -16px;
        margin-top: 0;
        flex-direction: row-reverse;
        button {
          margin-right: 0 !important;
          width: 50%;
          margin-bottom: 0;
          margin-top: 0;
        }
        &.full {
          button {
            width: 100%;
          }
        }
        button:first-child:last-child {
          width: calc(100% + 32px);
          border-radius: 0 0 8px 8px;
        }
        button:nth-child(1) {
          border-radius: 0 0 8px 0;
        }
        button:nth-child(2) {
          border-radius: 0 0 0 8px;
        }
      }
      button {
        margin-right: 20px;
      }
    }
  }

  &__card {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  @media (min-width: 901px) {
    &.horizontal {
      display: flex;
      flex-direction: row;

      .order-card__title {
        width: 288px;
      }

      .order-card__body {
        padding-top: 26px;
        width: 100%;
      }
    }
  }

  &__desc {
    display: contents;
    @include subtitle;
    color: var(--gray-thirdy);
    line-height: unset;
    padding-left: 16px;
    &-bottom {
      display: contents;
      @include subtitle;
      color: var(--gray-thirdy);
      line-height: unset;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    .order-card-supplier__desc::after {
      display: none;
    }

    // cursor: pointer;

    span {
      display: flex;
    }

    span:nth-child(1):not(.order-card-supplier__desc) {
      @include heading_two;
      color: var(--black-primary);
      margin-bottom: 4px;

      // &:hover {
      //   text-decoration: underline;
      // }
    }

    span:nth-child(2) {
      @include heading_six;
      color: var(--gray-secondary);
      margin-bottom: 4px;
    }

    span:nth-child(3) {
      @include subtitle;
      color: var(--black-primary);
    }
  }

  &__desc, &__desc-bottom {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    // justify-content: space-between;
    // margin-bottom: 16px;

    &-col {
      min-width: 25%;
    }

    &-description {
      width: 100%;
      .order-card-suplier__selected-list {
        width: 100%;
      }
    }

    &-description {
      position: relative;
      @media (max-width: 900px) {
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
        &:after {
          content: '';
          position: absolute;
          top: -8px;
          left: -50%;
          width: 200%;
          height: 1px;
          background: var(--lgray-primary);
        }
      }

      :after {
        // display: none!important;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      margin-bottom: 0px;
      padding-bottom: 16px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -50%;
        width: 200%;
        height: 1px;
        background: var(--lgray-primary);
      }

      &-col {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        width: 100%;

        &:not(&:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &-title {
      @include subtitle;
      color: var(--gray-thirdy);
    }

    &-subtitle {
      @include subtitle;
      color: var(--black-primary);
    }
  }

  &__selected {
    @media (max-width: 900px) {
      padding-top: 8px;
    }

    &-title {
      margin-bottom: 4px;
      @include heading_six;
      color: var(--black-primary);

      &.completed {
        @include heading_five;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 900px) {
        flex-direction: column;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      min-width: 150px;
      span:nth-child(1) {
        @include subtitle;
        color: var(--gray-thirdy);
        text-transform: capitalize;
        padding-bottom: 2px;
      }

      span:nth-child(2) {
        // @include heading_six;
        // color: var(--gray-thirdy);
      }
    }
  }
  &__tenders {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    padding: 24px;
    left: 0px;
    bottom: 0px;

    border-radius: 0px 16px 0px 8px;
    background: var(--lgray-secondary);

    @media (max-width: 900px) {
      padding: 16px 24px;
    }

    > span {
      display: flex;
      align-content: center;
      margin-right: 16px;
      position: relative;

      > div {
        margin-left: 4px;
      }
    }

    span:nth-child(1) {
      @include heading_five;
      color: var(--black-primary);
      margin-right: 26px;
    }

    span:nth-child(2) {
      @include paragraph;
      color: var(--black-primary);
    }

    span:nth-child(3) {
      @include paragraph;
      color: var(--gray-secondary);
    }
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid var(--violet-primary);

    &.primary {
      background: var(--violet-primary);
      color: var(--white);
    }

    &.outline {
      // border: 1px solid  var(--violet-primary);
      color: var(--violet-primary);
    }
  }
}
