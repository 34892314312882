.about-card {
  min-width: 316px;
  padding: 24px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--shadow-light);

  @media (max-width: 900px) {
    width: 100%;
  }

  &__title {
    @include heading_three;
    color: var(--black-primary);
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 16px;
  }

  &__content {
    &-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      margin-bottom: 8px;
    }

    &-name,
    &-desc {
      @include subtitle;
      color: var(--black-primary);
    }
    &-name {
      color: var(--gray-primary);
    }
  }
}
