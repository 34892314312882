.steps {
  display: flex;
  flex-wrap: nowrap;
  // justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .steps__nav {
    margin-right: 100px;

    .steps__finish {
      height: 100%;
      width: 100%;

      svg {
        display: inline-block;
        vertical-align: unset !important;
      }
      svg path {
        stroke: var(--white);
      }
    }

    .rc-steps-item-icon {
      width: 8px;
      height: 8px;
      margin-top: 7px;
      margin-right: 16px;
    }

    .rc-steps-item-finish {
      .rc-steps-item-icon {
        width: 20px;
        height: 20px;

        margin-left: -7px;
        margin-right: 10px;
        margin-top: 0px;

        background: var(--violet-primary);
        border-color: var(--violet-primary);
      }

      .rc-steps-item-title {
        cursor: pointer;
        color: var(--black-primary);
      }
    }
    .rc-steps-item-active {
      .rc-steps-item-icon {
        background: var(--violet-primary);
        border-color: var(--violet-primary);
      }
    }

    .rc-steps-item-wait {
      .rc-steps-item-icon {
        background: var(--violet-thirdy);
        border-color: var(--violet-thirdy);
      }
      .rc-steps-item-title {
        cursor: pointer;
      }
    }

    .rc-steps-vertical {
      .rc-steps-item-tail {
        position: absolute;
        left: 3px;
        top: 7px;
        height: 100%;
        width: 2px;
        padding: 8px 0 0px 0;

        &:after {
          width: 2px;
        }
      }

      .rc-steps-item-content {
        margin: 0;
        height: 86px;
      }

      .rc-steps-item-title {
        @include heading_three;
      }

      .rc-steps-item-active {
        .rc-steps-item-title {
          color: var(--black-primary);
        }
      }

      .rc-steps-item-wait {
        .rc-steps-item-title {
          color: var(--gray-thirdy);
        }
      }
    }

    .rc-steps-item-finish .rc-steps-item-tail:after {
      background: var(--violet-primary);
    }
    .rc-steps-item-process .rc-steps-item-tail:after {
      background: var(--violet-thirdy);
    }

    &__proccessing,
    &__wait {
    }

    &__proccessing {
      background: var(--violet-primary);
    }

    &__wait {
      background: var(--violet-thirdy);
    }
  }

  .step-button {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    @media (min-width: 901px) {
      button {
        min-width: 150px;
      }
    }

    @media (max-width: 900px) {
      button {
        width: 100%;
      }
    }
    button:nth-child(1) {
      margin-right: 16px;
    }
  }

  &__mobile {
    &-header {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-bottom: 20px;
    }

    &-icon {
      margin-right: 10px;
      svg path {
        stroke: var(--black-primary);
      }
    }

    &-title {
      @include heading_three;
    }
  }

  &__page {
    width: 66%;

    @media (max-width: 900px) {
      width: 100%;
    }

    &-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 900px) {
        margin-bottom: 32px;
      }
      > div {
        width: 48%;
        margin-bottom: 16px;

        > div {
          width: 100%;
        }

        @media (max-width: 900px) {
          width: 100%;
        }
      }

      .col-1 {
        width: 22% !important;

        @media (max-width: 900px) {
          width: 48% !important;
        }
      }

      .col-2 {
        width: 48% !important;

        @media (max-width: 900px) {
          width: 100% !important;
        }
      }

      .col-4 {
        width: 100% !important;
      }
    }

    .steps__mobile-indicator {
      height: 2px;
      width: 100%;
      position: relative;
    }

    .steps__mobile-count {
      @include paragraph;
      position: absolute;
      right: 24px;
      bottom: 28px;
      color: var(--monchrome-wf-600);

      @media (max-width: 900px) {
        bottom: 16px;
        left: 0;
      }
    }
  }
}
