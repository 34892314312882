.users-superadmin-page {
  .new-user-card {
    margin-top: 16px;
    margin-bottom: 52px;
    @media (max-width: 767px) {
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }
  &__title {
    @include heading_two;
  }

  .users-company-page__banned {
    margin-top: 32px;
  }
}
