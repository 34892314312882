@import './assets/fonts/fonts.scss';
@import './variables.scss';
@import './mixins.scss';
@import './styles/link.scss';
@import './styles/icon.scss';
@import './styles/button.scss';
@import './styles/form-input.scss';
@import './styles/nav-list.scss';
@import './styles/confirm-popup.scss';
@import './styles/actions.scss';
@import './styles/dropdown.scss';
@import './styles/steps.scss';
@import './styles/notification-popup.scss';
@import './styles/notification-alert.scss';
@import './styles/side-bar.scss';
@import './styles/main-header.scss';
@import './styles/page-layout.scss';
@import './styles/sign-in.page.scss';
@import './styles/tab.scss';
@import './styles/reset-password.scss';
@import './styles/new-password.scss';
@import './styles/company-item.scss';
@import './styles/company-page.scss';
@import './styles/text.scss';
@import './styles/personal-info.scss';
@import './styles/company-detail-page.scss';
@import './styles/new-company-page.scss';
@import './styles/success-screen.scss';
@import './styles/password-user.scss';
@import './styles/spinner.scss';
@import './styles/alert.scss';
@import './styles/project-item.scss';
@import './styles/project.scss';
@import './styles/pagination.scss';
@import './styles/users-company-page.scss';
@import './styles/user-company-item.scss';
@import './styles/order.scss';
@import './styles/accordion.scss';
@import './styles/status.scss';
@import './styles/order-card.scss';
@import './styles/about-card.scss';
@import './styles/project-detail-page.scss';
@import './styles/orders-page.scss';
@import './styles/new-user-card.scss';
@import './styles/company-order-item.scss';
@import './styles/my-company-user.scss';
@import './styles/admins-page.scss';
@import './styles/add-user-page.scss';
@import './styles/order-supplier-page.scss';
@import './styles/order-card-supplier.scss';
@import './styles/orders-supplier-page.scss';
@import './styles/text-area-input.scss';
@import './styles/add-order.scss';
@import './styles/datepicker.scss';
@import './styles/users-superadmin-page.scss';
@import './styles/account-user.scss';
@import './styles/my-company-page.scss';
@import './styles/order-detail-customer.scss';
@import './styles/offer-card-customer.scss';
@import './styles/supplier-company-info.scss';
@import './styles/analytics.scss';
@import './styles/project-detail-page-customer.scss';
@import './styles/text-area-input.scss';
@import './styles/add-project.scss';
@import './styles/order-offer.scss';
@import './styles/time-picker.scss';
@import './styles/supplier-company-info.scss';
@import './styles/notification.modal.scss';

* {
  box-sizing: border-box;
  font-family: 'Inter Regular', sans-serif;
}

body {
  // background: var(--lgray-secondary);
  height: 100%;
}

body,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none !important;
}

.popup-overlay {
  background: var(--overlay-color);
}

@media (max-width: 768px) {
  input,
  textarea {
    font-size: 16px !important;
  }
}

.edited-label {
  color: var(--gray-primary);
  @include paragraph;
  display: block;
  margin-left: 8px;
  margin-top: 3px;
}

.status-container {
  display: flex;

  .edited-label {
    color: var(--gray-primary);
    @include paragraph;
    display: block;
    // margin-left: 8px;
    margin-top: 10px;
  }
}

// TODO: REFACTOR THIS
.lang-switcher-temp {
  height: auto;
}
// .ios-mobile-fix-autozoom {
//   @media (max-width: 768px) {
//     input {
//       font-size: 16px !important;
//     }
//   }
// }
