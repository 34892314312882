.admins-page {
  &__add {
    > div {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 16px;

      > div {
        width: 50%;
      }

      > div:nth-child(1) {
        margin-right: 8px;
      }
      > div:nth-child(2) {
        margin-left: 8px;
      }

      @media (max-width: 900px) {
        flex-direction: column;

        > div {
          width: 100% !important;
        }

        > div:nth-child(1) {
          margin-right: 0px;
          margin-bottom: 16px;
        }

        > div:nth-child(2) {
          margin-left: 0px;
        }
      }
    }
  }

  &__list {
    padding-top: 16px;

    &-title {
      @include heading_two;
      display: block;
      margin-bottom: 0px;
    }

    &-items {
      margin-bottom: 16px;
    }
  }
}
