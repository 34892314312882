.notification-alert {
  width: 100%;

  &__inner {
    border-radius: 4px;

    span {
      @include subtitle;
      display: block;
      padding: 8px 16px;
    }

    &.success {
      background: var(--green-secondary);
      span {
        color: var(--green-success);
      }
    }

    &.warning {
      background: var(--yellow-thirdy);
      span {
        color: var(--yellow-primary);
      }
    }

    &.error {
      background: var(--red-thirdy);
      span {
        color: var(--red-primary);
      }
    }
  }
}
