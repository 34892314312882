.alert {
    &_container {
        .notification-alert {
            margin-bottom: 10px;
        }
        margin-bottom: 31px;
        @media(max-width: 767px) {
            margin-bottom: 21px;
        }
    }
}