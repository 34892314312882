.users-company-page {
  &.banned {
    h2 {
      @include heading_two;
    }
    .user-company-item__inner {
      background: var(--red-banned);
      box-shadow: 0px 4px 20px rgba(139, 148, 179, 0.14);
    }
  }
  &__banned {
    margin-top: 16px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -16px;
      width: 100%;
      height: 1px;
      background: var(--lgray-primary);
    }
  }
  &__header {
    margin-bottom: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      @include heading_one;
      color: var(--black-primary);
    }
  }

  &__list {
    padding: 16px 0 24px;

    &-item:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--violet-thirdy);

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &__title {
      @include paragraph;
      color: var(--gray-primary);
    }
  }
}
