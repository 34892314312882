.add-user-page {
  &__inner {
  }

  .add-user-page_main-title {
    @include heading_one;
  }
  @media (max-width: 900px) {
    .container_title {
      margin-bottom: 6px;
      h1 {
        flex-wrap: wrap;
        .info-msg {
          width: 100%;
          margin: 16px 0 0;
        }
      }
    }
  }

  .container_title-text {
    h1 {
      > span:nth-child(1) {
        width: 400px;
      }
    }

    @media (max-width: 1015px) {
      .info-msg {
        margin-left: 0px !important;
      }

      h1 {
        > span:nth-child(1) {
          width: 330px !important;
        }
      }
    }
    .info-msg {
      margin-left: 60px;
      width: 288px;

      @media (max-width: 1080px) {
        margin-left: 0px !important;
      }

      > div {
        margin-right: 10px;
      }
    }
  }
  &__form {
    &-inner {
      display: flex;
      flex-wrap: wrap;
      padding-top: 12px;
      gap: 0 16px;
      max-width: 656px;

      .form-input {
        width: 100%;
        margin-bottom: 16px;
      }

      @media (min-width: 901px) {
        .form-input {
          max-width: 320px;
        }
      }
    }

    &-btn {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 16px;

      @media (min-width: 901px) {
        button {
          width: 148px;
        }
      }

      > button:nth-child(1) {
        margin-right: 16px;
      }
    }
  }

  .info-msg {
    @include subtitle;
    display: flex;
    position: relative;
    max-width: 316px;
    color: var(--gray-primary);
  }
}
