.company-detail-page {
  @media (min-width: 901px) {
    .company-page__header-title {
      margin-left: 44px;
    }
  }

  .company-page__header-type {
    svg {
      cursor: pointer;

      path {
        stroke: var(--gray-primary);
      }
    }
  }

  .company-page {
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 24px;

      &-type {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        span {
          @include subtitle;
          color: var(--gray-thirdy);
          display: flex;

          > div {
            margin-right: 26px;
          }

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      &-title.mobile {
        display: flex;
        > div {
          margin-right: 8px;

          path {
            stroke: var(--black-primary);
          }
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
    }
    &__stat {
      display: flex;
      flex-wrap: nowrap;
      max-width: 577px;
      gap: 16px;

      &.supplier {
        .company-additional-card {
          // width: 233px !important;
        }
      }

      @media (max-width: 900px) {
        width: 100vw;
        position: relative;
        left: -20px;
        overflow-x: auto;
        padding: 8px 8px 8px 20px;

        > div {
          margin-right: 8px;
          width: 182px;
        }

        &.supplier {
          .company-additional-card {
            width: 233px !important;
          }
        }

        .company-additional-card__top {
          width: 133px;
        }
      }

      > div {
        // margin-right: 16px;
        width: 100%!important;
      }
    }
    &__desc {
      margin-top: 24px;

      @media (max-width: 900px) {
        margin-top: 8px !important;
      }
    }
  }

  .company-card {
    width: 577px;
    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-light);

    @media (max-width: 900px) {
      width: 100%;
    }

    &__title {
      @include heading_three;
      color: var(--black-primary);

      margin-bottom: 16px;
    }

    &__content {
      &-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        margin-bottom: 8px;
      }

      &-name,
      &-desc {
        @include subtitle;
        color: var(--black-primary);
      }

      &-name {
        color: var(--gray-primary);
      }
    }
  }

  .company-additional-card {
    width: 182px;
    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: var(--shadow-light);

    @media (max-width: 900px) {
      padding: 16px;
    }

    &__top {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      @media (max-width: 900px) {
        margin-bottom: 0;
      }
    }

    &__bottom {
      @media (max-width: 900px) {
        display: none;
      }
    }

    &__icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 16px;

      background: var(--violet-thirdy);

      svg path {
        stroke: var(--violet-primary);
      }
    }

    &__text {
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        @include heading_three;
        color: var(--black-primary);
      }
      span:nth-child(2) {
        @include paragraph;
        color: var(--gray-primary);
      }
    }

    button {
      width: 100%;
    }
  }
}
