.timepicker {
  width: 288px;

  &__label-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    label {
      @include subtitle;
      display: block;
      color: var(--black-primary);

      &:first-letter {
        text-transform: uppercase;
      }
    }

    label.error {
      color: var(--gray-primary);
    }
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 14px;

    > svg {
      display: block !important;
    }
  }
  &__input-wrap {
    position: relative;
    margin-bottom: 2px;

    // &.icons {
    //   input {
    //     padding-right: 30px;
    //   }
    // }

    &.errors {
      > input {
        border: 1px solid var(--red-secondary);
      }
    }

    input {
      @include paragraph;
      width: 100%;
      padding: 12px 8px 12px 32px;
      border-radius: 8px;
      border: 1px solid var(--gray-thirdy);
      outline: none;
      height: 44px;

      &::placeholder {
        @include paragraph;
        color: var(--gray-thirdy);
      }
    }

    input:not(.control-input):focus,
    input:not(.control-input):active {
      outline: none;
      box-shadow: var(--shadow-light);
      border: 1px solid var(--white);
      border-radius: 8px 8px 0 0;
    }

    input:not(.error):focus {
      // box-shadow: 0px 0px 10px rgba(139, 148, 179, 0.7);
    }

    .danger-icon {
      display: flex;
      position: absolute;
      right: 8px;
      width: 16px;
      height: 16px;
      top: calc(50% - 9px);

      svg path {
        stroke: var(--red-secondary);
      }
    }
  }

  &__controls {
    position: absolute;
    top: 100%;
    z-index: 10;
    width: 100%;
    background: var(--white);
    box-shadow: var(--shadow-light);
    border-radius: 0 0 8px 8px;
    padding: 8px 14px 12px;
    border-top: 1px solid var(--lgray-primary);

    display: flex;

    > div:first-child {
      margin-right: 36px;
    }

    .timepicker__control {
      display: flex;
      align-items: center;

      &-left,
      &-right {
        cursor: pointer;
        svg {
          display: block !important;
          path {
            stroke: var(--violet-primary) !important;
          }
        }
      }
    }

    input {
      border-radius: 4px;
      padding: 8px 16px;
      border: 1px solid var(--lgray-primary);
      width: 55px;
      margin: 0 8px;
    }
  }

  .errors {
    // input {
    //   border: 1px solid var(--red-secondary);
    //   outline: none;
    // }
  }

  p.error {
    @include subtitle;
    margin: 2px 0 0;
    width: 100%;
    text-align: left;
    color: var(--red-secondary);
  }

  &.disabled {
    label {
      color: var(--gray-primary);
    }

    input {
      background: #f1f0f3;
      border: 1px solid var(--gray-thirdy);
      box-shadow: none !important;
      &::placeholder {
        color: var(--gray-thirdy);
      }
    }
  }

  .focused {
    input:not(.control-input) {
      outline: none;
      box-shadow: var(--shadow-light);
      border: 1px solid var(--white);
      border-radius: 8px 8px 0 0;
    }

    .icon {
      svg {
        path {
          stroke: var(--violet-primary) !important;
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  #timepicker-mm-input,
  #timepicker-hh-input {
    cursor: pointer;
  }

  #timepicker-hh-dec:focus-visible,
  #timepicker-hh-inc:focus-visible,
  #timepicker-mm-dec:focus-visible,
  #timepicker-mm-inc:focus-visible {
    outline: none;
    opacity: 0.7;
  }

  #timepicker-hh-dec:focus,
  #timepicker-hh-inc:focus,
  #timepicker-mm-dec:focus,
  #timepicker-mm-inc:focus {
    outline: none;
    opacity: 0.7;
  }
}
