.account-user {
    display: flex;
    flex-wrap: nowrap;



    &__content-header {
      @media(max-width: 767px) {
        .container_title {
          margin-bottom: 8px;
        }
      }
      @media(min-width: 767px) {
        display: flex;
      }
      &_content {
        div {
          display: flex;
        }
        @media(min-width: 767px) {
          max-width: 300px;
          align-items: end;
          margin-left: 130px;
        }
        @media(max-width: 767px) {
          margin-bottom: 16px;
        }
        svg {
          width: 32px;
          display: block;
          margin-right: 6px;
        }
        display: flex;
        span {
          @include subtitle;
          color: var(--gray-primary);
        }
      }
    }

    .filed-add-user {
        margin-bottom: 16px;
        @include subtitle;
        @media (min-width: 767px) {
            max-width: 320px;
            color:  var(--gray-primary);
        }
    }
  
    &__header {
      display: none;
    }
  
    @media (max-width: 500px) {
      flex-direction: column;
  
      &__header {
        display: flex;
        align-items: center;
  
        height: 56px;
        padding: 8px 16px;
        margin-bottom: 38px;
  
        background-color: var(--gray-primary);
        color: var(--white);
  
        svg {
          width: 160px;
        }
      }
  
      > div {
        width: 100%;
      }
    }
  
    &__content {
      //   display: flex;
      //   justify-content: center;
    }
  
    &__form {
      display: flex;
      flex-wrap: wrap;
      // min-width: 320px;
      // max-width: 320px;
      padding-top: 16px;
      gap: 0 16px;
      max-width: 656px;
  
      .form-input {
        width: 100% !important;
        margin-bottom: 16px;
        @media (min-width: 767px) {
          max-width: 320px;
        }
      }
  
      &-inner {
      }
  
      &-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;

        @media(max-width: 767px) {
          button {
            width: calc(50% - 10px);
          }
        }
  
        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  
  