:root {
  --black-primary: #181818;
  --gray-primary: #636c79;
  --gray-secondary: #59616f;
  --gray-thirdy: #a7aeba;
  --black: #1E1E1F;

  --lgray-primary: #e4e8ee;
  --lgray-secondary: #f8f8fa;
  --lgray-thirdy: #eff0f2;

  --white: #ffffff;

  --violet-primary: #4446b0;
  --violet-secondary: #4d4e8d;
  --violet-thirdy: #e8e8ff;

  --green-success: #7fb39a;
  --green-secondary: #e8f1ec;
  --green-accept: #7fb29a;

  --yellow-primary: #b99537;
  --yellow-secondary: #f0d284;
  --yellow-thirdy: #f9edce;

  --red-primary: #d85346;
  --red-secondary: #e66558;
  --red-banned: #fbebea;
  --red-thirdy: #f9c9c4;

  --blue-primary: #456fae;
  --blue-secondary: #e0edff;

  --shadow-hard: 0px 0px 10px rgba(139, 148, 179, 0.3);
  --shadow-medium: 0px 4px 15px rgba(139, 148, 179, 0.24);
  --shadow-light: 0px 4px 20px rgba(139, 148, 179, 0.14);
  --shadow-light-top: 0px -4px 20px rgba(139, 148, 179, 0.14);

  --overlay-color: #18181836;

  --monchrome-wf-600: #717d96;
}
