.pagination {
    display: flex;

    // @media(max-width: 767px) {
    //     justify-content: center;
    // }

    li {
        margin-right: 8px;
        background: var(--white);
        border-radius: 50%;
        height: 32px;
        width: 32px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-thirdy);
        &.active {
            background-color: var(--violet-primary);
            color: var(--white);
            pointer-events: none;
        }
    }

    &-icon {
        background: var(--white);
        border-radius: 50%;
        height: 32px;
        width: 32px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;

        &_active {
            cursor: pointer;
            path {
                stroke: var(--violet-primary);
            }
        }
        &_inactive {
            cursor: pointer;
            pointer-events: none;
            path {
                stroke: var(--gray-thirdy);
            }
        }
    }

}