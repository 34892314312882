.new-company {
  display: flex;
  justify-content: space-between;

  .steps__page-item {
    > .full-width {
      > .form-input {
        width: 100% !important;
      }
    }
  }
  .company-page__header {
    @media (max-width: 900px) {
      margin-bottom: 7px;
    }
  }

  button {
    min-width: 144px;
  }
}

.fix-ios-company-page-keyboard-scroll-page {
  .page-layout__content {
    @media (max-width: 900px) {
      min-height: unset !important;
    }
  }
}

.fix-android-company-page-keyboard-scroll-page {
  .page-layout__content {
    @media (max-width: 900px) {
      height: 100vh !important;
    }
  }
  .new-company {
    padding-bottom: 150px;
  }
}
