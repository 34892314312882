.order-card {
  padding: 24px;
  border-radius: 8px;
  position: relative;

  overflow: hidden;
  background: var(--white);
  box-shadow: var(--shadow-light);
  cursor: pointer;

  &.customer {
    .order-card__selected-list {
      width: calc(100% - 288px);
    }

    @media (min-width: 901px) {
      .order-card__selected-item,
      .order-card__desc-col {
        width: 25%;
      }
    }

    @media (max-width: 900px) {
      padding: 16px 16px 8px;
      .order-card__title {
        padding-right: 15px;
      }
    }
    .order-card__selected-title {
      width: 100%;
      // display: contents;
      @media (min-width: 901px) {
        width: 288px;
      }
    }
    .order-card__title {
      cursor: pointer;
    }

    .order-actions {
      position: absolute;
      right: 0px;

      @media (min-width: 901px) {
        top: 23px;
        right: 8px;

        .actions {
          top: 0 !important;
          right: 0px !important;
        }
      }
    }

    cursor: default;
    .actions {
      top: -28px;
      z-index: 22;
      cursor: pointer;
      width: 0;
      right: 10px;
      @media (max-width: 900px) {
        top: 17px;
        right: 30px;
        position: absolute;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 24px 24px 8px;
  }

  &.horizontal.tender {
    padding: 24px;

    .no-accept-offers {
      margin-bottom: 52px;
    }

    @media (max-width: 900px) {
      padding: 16px 16px 38px;
      .no-accept-offers {
        margin-bottom: auto !important;
      }
    }
  }

  &.tender {
    padding: 24px 24px 84px;

    @media (max-width: 900px) {
      padding: 24px 24px 38px;

      .no-accept-offers {
        margin-bottom: auto;
      }
    }
  }
  &__status {
    display: flex;
    align-items: flex-start;
  }

  .order-card__body {
    position: relative;
  }

  @media (min-width: 901px) {
    &.horizontal {
      .order-card__inner {
        display: flex;
        flex-direction: row;
      }

      .order-card__title {
        width: 288px;
      }

      .order-card__body {
        padding-top: 26px;
        width: 100%;
        position: relative;
      }

      .order-card__selected.not-new {
        display: flex;
        margin-top: 8px;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    @media (max-width: 767px) {
      width: fit-content;
    }

    span {
      display: flex;
    }

    span:nth-child(1) {
      @include heading_two;
      color: var(--black-primary);
      margin-bottom: 4px;
      margin-top: 4px;
      &:hover {
        // text-decoration: underline;
      }
    }

    span:nth-child(2) {
      @include heading_six;
      color: var(--gray-secondary);
      margin-bottom: 4px;
    }

    span:nth-child(3) {
      @include subtitle;
      color: var(--gray-thirdy);
    }
  }

  &__desc {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 26px;
    @media (min-width: 900px) {
      margin-top: 25px;
    }
    &-col {
      max-width: 250px;
      min-width: 100px;
    }

    @media (min-width: 1300px) {
      &-col {
        min-width: 150px;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      margin-bottom: 0px;
      padding-bottom: 16px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -50%;
        width: 200%;
        height: 1px;
        background: var(--lgray-primary);
      }

      &-col {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        min-width: 100% !important;
        max-width: 100% !important;
        width: 100%;

        &:not(&:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &-title {
      @include subtitle;
      color: var(--gray-thirdy);
    }

    &-subtitle {
      @include subtitle;
      color: var(--black-primary);
    }
  }

  &__selected {
    @media (max-width: 900px) {
      padding-top: 8px;
    }

    &-title {
      margin-bottom: 4px;
      @include heading_six;
      color: var(--black-primary);

      &.completed {
        @include heading_five;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 900px) {
        flex-direction: column;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      min-width: 150px;
      max-width: 250px;
      padding-right: 10px;

      @media (max-width: 900px) {
        margin-bottom: 8px;
      }

      span:nth-child(1) {
        @include subtitle;
        color: var(--gray-thirdy);
        text-transform: capitalize;
        display: block;
        margin-bottom: 2px;
      }

      a {
        font-size: 12px !important;
      }

      span:nth-child(2) {
        // @include heading_six;
        // color: var(--gray-thirdy);
      }
    }
  }
  &__tenders {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    padding: 24px;
    left: 0px;
    bottom: 0px;

    border-radius: 0px 16px 0px 8px;
    background: var(--lgray-secondary);

    @media (max-width: 900px) {
      padding: 16px 24px;
      width: 100%;
      border-radius: 0px;
    }

    > span {
      display: flex;
      align-content: center;
      margin-right: 16px;
      position: relative;

      > div {
        margin-left: 4px;
      }
    }

    span:nth-child(1) {
      @include heading_five;
      color: var(--black-primary);
      margin-right: 26px;
    }

    span:nth-child(2) {
      @include paragraph;
      color: var(--black-primary);
    }

    span:nth-child(3) {
      @include paragraph;
      color: var(--gray-secondary);
    }

    @media (max-width: 900px) {
      span:nth-child(1) {
        margin-right: auto !important;
      }

      span:nth-child(3) {
        margin-right: 0 !important;
      }
    }
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid var(--violet-primary);
    @include subtitle;

    &.primary {
      background: var(--violet-primary);
      color: var(--white);
    }

    &.outline {
      // border: 1px solid  var(--violet-primary);
      color: var(--violet-primary);
    }
  }
}
