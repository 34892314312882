.new-user-card {
  justify-content: center;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--shadow-light);
  text-align: center;

  &__top {
    padding: 24px 24px 16px;

    @media (max-width: 900px) {
      padding: 24px;
    }
  }

  &__bottom {
    padding: 0px 24px 24px;

    @media (max-width: 900px) {
      padding: 0;
      .btn {
        background-color: var(--lgray-secondary);
      }
    }

    &.safari-fix {
      padding: 0 !important;
      bottom: -2px !important;
      position: relative !important;
    }
  }

  &__title {
    margin-bottom: 8px;
    span {
      @include heading_two;
      color: var(--black-primary);
    }
  }

  &__subtitle {
    padding: 0 52px;

    @media (max-width: 900px) {
      padding: 0;
    }
    span {
      @include paragraph;
      color: var(--gray-primary);
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    width: 100%;

    > button {
      width: 240px;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }
}
