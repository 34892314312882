.password-user {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  &__header {
    display: none;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;

      height: 56px;
      padding: 8px 16px;
      margin-bottom: 38px;

      background-color: var(--gray-primary);
      color: var(--white);

      svg {
        width: 160px;
      }
    }

    > div {
      width: 100%;
    }
  }

  &__content {
    //   display: flex;
    //   justify-content: center;
  }

  &__form {
    width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    // flex-direction: column;
    // min-width: 320px;
    // max-width: 320px;
    padding-top: 16px;
    gap: 0 16px;

    // .form-input:nth-child(1) {
    //   order: 1;
    // }
    // .form-input:nth-child(2) {
    //   order: 3;
    // }
    // .form-input:nth-child(3) {
    //   order: 2;
    // }

    .form-input {
      margin-bottom: 16px;
      @media (min-width: 767px) {
         max-width: 320px;
         width: 320px;
      }
      @media(max-width: 766px) {
        width: 100%;
      }
    }

    &-inner {
      width: 100%;
    }

    &-btn {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
