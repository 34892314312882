.success-screen {
  width: 432px;
  text-align: center;

  @media (max-width: 500px) {
    margin-top: 149px;
  }

  &__icon {
    margin-bottom: 24px;
  }

  &__title {
    @include heading_one;
    margin-bottom: 8px;
  }

  &__subtitle {
    @include subtitle;
    color: var(--gray-primary);
  }

  .link {
    @include subtitle;
    display: inline-block;
    margin-top: 8px;
  }

  &.error-screen {
    .success-screen__icon {
      svg path {
        stroke: var(--red-secondary);
      }
    }
  }
}
